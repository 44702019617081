import { UIPanel, UIDiv } from './libs/ui.js';
import { DIALOG_SCREEN_STATES } from './Config.EJX.js';

function DialogGenericLoading( editor ) {
	const container = new UIDiv();

	const signals = editor.signals;

	container.dom.style.width = '150px';
	container.dom.style.height = '80px';

	signals.launchDialog.add( async ( screenState ) => {

		if ( screenState === DIALOG_SCREEN_STATES.LOADING ) {


            signals.loadingStarted.dispatch();

		}

	} );

	signals.closeDialog.add( () => {

		signals.loadingFinished.dispatch();


	} );

	return container;
}

function DialogLoading( editor ) {

	const container = new UIPanel();
	return container;

}

export { DialogLoading, DialogGenericLoading };
