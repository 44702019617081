/**
 * @param {number} timeout Time to sleep for
 * @returns {} 
 */
export function sleep(timeout) {
    return new Promise((res) => {
        setTimeout(res, timeout);
    })
}

/**
 * @param {() => Promise<unknown>} method 
 * @param {{attempts?: number, sleepMultiplier?: number, sleepStep?: number}} opts 
 */
export async function retry(method, opts) {
    const maxAttempts = opts && opts.attempts ? opts.attempts : 3;
    const sleepStep = opts && opts.sleepStep ? opts.sleepStep : 120;
    const sleepPower = opts && opts.sleepPower ? opts.sleepPower : 1.2;

    let result = null;
    let ok = false;
    let attempt = 0;
    while (true) {
        try {
            result = await method();
            ok = true;
        } catch(error) {
            if (attempt >= maxAttempts) {
                throw error; 
            } else {
                attempt += 1
                await sleep(Math.pow(attempt * sleepStep, sleepPower));
            }
        }

        if (ok) break;
    }
    return result;
}

export function throttle(timeoutMs, method) {
    let to = undefined;
    let needsCallAgain = false;

    let latestArgs = [];

    const prepareNextCall = () => {
        to = setTimeout(() => {
            to = undefined;
            if (needsCallAgain) {
                method(...latestArgs)
                prepareNextCall();
            }
        }, timeoutMs)
    }

    return function(...args) {
        latestArgs = [...args];
        if (!to) {
            method(...args);
            prepareNextCall();
        } else {
            needsCallAgain = true;
        }
    }
}
