import { UIPanel } from './libs/ui.js';

function MenubarPlay( editor ) {

	const signals = editor.signals;
	const strings = editor.strings;

	const container = new UIPanel();
	container.setClass( 'menu' );

	let isPlaying = false;

	const title = new UIPanel();
	title.setClass( 'title' );
	title.setTextContent( strings.getKey( 'menubar/play' ) );

	const setPlay = () => {
		isPlaying = false;
		title.setTextContent( strings.getKey( 'menubar/play/play' ) );
	}

	const setStop = () => {
		isPlaying = true;
		title.setTextContent( strings.getKey( 'menubar/play/stop' ) );
	}

	title.onClick( function () {

		if ( isPlaying === false ) {
			setStop();
			signals.startPlayer.dispatch();

		} else {
			setPlay();
			signals.stopPlayer.dispatch();

		}

	} );
	container.add( title );

	signals.startPlayer.add(setStop.bind(this));
	signals.stopPlayer.add(setPlay.bind(this));

	return container;

}

export { MenubarPlay };
