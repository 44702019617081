import { UIPanel, UIRow, UIInput, UICheckbox, UIText, UISpan, UISelect, UIDiv } from './libs/ui.js';

/* import { SidebarProjectMaterials } from './Sidebar.Project.Materials.js'; */
import { ProjectTypeParametersPanel } from './Sidebar.Project.ProjectType.EJXCube.js';
import { SidebarProjectPostprocessing } from './Sidebar.Project.Postprocessing.js';

function SidebarPreview(editor) {
    const config = editor.config;
    const signals = editor.signals;
    const strings = editor.strings;

    const container = new UIDiv();

    const typeSettings = new UIDiv();
    let ejxCubeSettings;

    const updateTypeSettings = () => {
        typeSettings.clear();
        const type = config.getKey('project/type');
        switch(type){
            case 'EJXCube':
                if(ejxCubeSettings) typeSettings.add(ejxCubeSettings);
                else {
                    ejxCubeSettings = new ProjectTypeParametersPanel( editor );
                    typeSettings.add(ejxCubeSettings);
                }
                break;
        }
    }
    container.add(typeSettings);
    
    updateTypeSettings();
    signals.switchedProjectType.add(updateTypeSettings);

    container.add( new SidebarProjectPostprocessing( editor) );

    return container;

}

export { SidebarPreview };
