import * as THREE from 'three';

import axios from 'axios';
import { zipSync, strToU8 } from 'fflate';

import { DIALOG_SCREEN_STATES, EJX_API_URL } from './Config.EJX.js';
import { kebabCase } from './utils.EJX.js';
import { pushNotification } from './EJXNotification.js';
import { ExportUtils } from './utils/importexport.js';
import { captureException } from '@sentry/astro';
import AuthUtils from './utils/auth.js';

/**
 * @param {any} editor 
 * @returns {} 
 */
function EJXAPI( editor ) {

	const config = editor.config;
	const signals = editor.signals;

	const useApi = ( endpoint, auth = false ) => {

		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json'
		};
		if ( auth ) {

			headers.Authorization = `Bearer ${config.getKey( 'auth/IdToken' )}`;

		}

		const api = axios.create( {
			baseURL: EJX_API_URL,
			headers
		} );

        const errorContext = new Error('Thrown at:');
		api.interceptors.request.use(
			async conf => {

				if ( auth ) {

					conf.headers = {
						Authorization: `Bearer ${config.getKey( 'auth/IdToken' )}`,
						Accept: 'application/json',
						'Content-Type': 'application/json'
					};

				} else {

					conf.headers = {
						Accept: 'application/json',
						'Content-Type': 'application/json'
					};

				}

				return conf;

			},
			error => {

				Promise.reject( error );

			}
		);

		api.interceptors.response.use(
			response => response,
			async error => {

                // Axios error stacks don't show where the function was called from, this improves it.
                // https://github.com/axios/axios/issues/2387
                if (errorContext.stack) {
                    error.stack = `${error.stack}\n${errorContext.stack}`
                }

				const originalRequest = error.config;

                const isRetryRequest = originalRequest._retry;
                const is401Response = error.response && error.response.status === 401;

				if ( is401Response && !isRetryRequest && originalRequest.url !== '/auth/token' ) {

					originalRequest._retry = true;
					if ( config.getKey( 'auth/RefreshToken' ) ) {

                        try {
                            const result = await refreshTokens( config.getKey( 'auth/RefreshToken' ) );
                            config.setKey( 'auth/IdToken', result.IdToken );
                            config.setKey( 'auth/AccessToken', result.AccessToken );
                            config.setKey( 'auth/RefreshToken', result.RefreshToken );
                        } catch (err) {
                            if (err instanceof Error && err.message === 'Refresh token failed') {
                                editor.signals.logout.dispatch();
                                editor.signals.hideOverlay.dispatch();
                                editor.signals.stopPlayer.dispatch();
                                pushNotification(editor, {
                                    type: 'error',
                                    title: 'Session has expired',
                                    description: 'Please login again',
                                })
                            } else {
                                throw err;
                            }
                        }

					}

					return api( originalRequest );

				}

                if (is401Response) {
                    // TODO: Show the 
                    handleSessionExpired();
                }

				// console.log( error.response.data.message );
				// console.log( 'orig', originalRequest );

				// if ( error.response.status === 500 && originalRequest._retry && error.response.data.message === 'Refresh Token has expired' ) {

				// 	alert( 'Session expired, please login again' );
				// 	signals.logout.dispatch();

				// }

				return Promise.reject( error );

			}
		);

		return {
            put: async (payload) => {

                const { data } = await api.put( endpoint, payload );
                return data;

            },
            get: async (payload) => {

                const { data } = await api.get( endpoint, payload );
                return data;

            },
            post: async (payload) => {

                const { data } = await api.post( endpoint, payload );
                return data;

            },
            delete: async (payload) => {

                const { data } = await api.delete(endpoint, payload);
                return data;

            }
		};

	};

	const refreshTokens = async refreshToken => {

		try {

			const { post } = useApi( '/auth/token' );
			const result = await post( {
				refreshToken: refreshToken
			} );
			return result.auth_tokens;

		} catch ( err ) {

            if (err.response) {
                console.error('Error refreshing token: ', err);
            }

			if ( err.response.status === 401 ) {

                const error = new Error('Refresh token failed')
                error.cause = err;
                throw error;

			} else {

                throw err;

            }

		}

	};

    const handleSessionExpired = () => {
        pushNotification(editor, {
            type: 'error',
            title: 'Session Expired',
            description: 'Please login again.'
        })
        signals.logout.dispatch();
        signals.loadingFinished.dispatch();
        signals.closeDialog.dispatch();
        signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.LOGIN)
    }

	return {
		createAccount: async ( username, name, email ) => {
            console.assert(username && typeof username === 'string', `createAccount() incorrect parameter for 'username'.  Found '${username}'`);
            console.assert(name && typeof name === 'string', `createAccount() incorrect parameter for 'name'.  Found '${name}'`);
            console.assert(email && typeof email === 'string', `createAccount() incorrect parameter for 'email'.  Found '${email}'`);

			try {

				const body = {
					username,
					name,
					email
				};
				const { post } = useApi( '/accounts', true );
				const data = await post( body );

				return data;


			} catch ( error ) {

				console.error( error );
				alert( 'Error creating account' );

			}

		},
		getWorkspacesByUser: async ( username ) => {
            console.assert(username && typeof username === 'string', `getWorkspacesByUser() incorrect parameter for 'username'.  Found '${username}'`);

			try {

				const body = { username };
				const { post } = useApi( '/workspaces/list', true );
				const data = await post( body );

				return data;

			} catch ( error ) {

                captureException( error );
				console.error( error );
				//throw error;

			}

		},
        /**
         * Gets a list of projects.
         * @param {string} workspaceId
         * @param {string} username
         */
		getProjects: async ( workspaceId, username ) => {
            console.assert(workspaceId && typeof workspaceId === 'string', `getProjects() incorrect parameter for 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(username && typeof username === 'string', `getProjects() incorrect parameter for 'username'.  Found '${username}'`);

            const body = { workspaceId, username };
            const { post } = useApi( '/projects/list', true );
            const data = await post( body );

            return data;
		},
		getLatestVersion: async ( workspaceId, projectId ) => {
            console.assert(workspaceId && typeof workspaceId === 'string', `getLatestVersion() incorrect parameter for 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(projectId && typeof projectId === 'string', `getLatestVersion() incorrect parameter for 'projectId'.  Found '${projectId}'`);

			const response = await fetch( `${EJX_API_URL}/version/${workspaceId}/${projectId}`, {
				method: 'get',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				}
			} );
			const data = await response.json();
			return data;

		},
		createProject: async ( workspaceId, username, name ) => {
            console.assert(workspaceId && typeof workspaceId === 'string', `createProject() incorrect parameter for 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(username && typeof username === 'string', `createProject() incorrect parameter for 'username'.  Found '${username}'`);
            console.assert(name && typeof name === 'string', `createProject() incorrect parameter for 'name'.  Found '${name}'`);

            const body = { workspaceId, createdBy: username, name, projectId: kebabCase( name ) };
            const { post } = useApi( '/projects', true );
            const data = await post( body );
            config.setKey( 'project/id', data.projectId );

            return { statusCode: 200, data };

		},
        /**
         * Deletes a project.
         * @param {string} workspaceId
         * @param {string} projectId
         */
		deleteProject: async ( workspaceId, projectId ) => {
            console.assert(workspaceId && typeof workspaceId === 'string', `deleteProject() incorrect parameter for 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(projectId && typeof projectId === 'string', `deleteProject() incorrect parameter for 'projectId'.  Found '${projectId}'`);

			signals.setLoadingStatus.dispatch("Deleting project...");
			signals.loadingStarted.dispatch();
			try {

                const data = await useApi( `/projects/${workspaceId}/${projectId}`, true ).delete();
				signals.projectDeleted.dispatch(projectId);
				signals.loadingFinished.dispatch();
				return { statusCode: 200, data };

			} catch ( error ) {

				console.error( error );
				signals.loadingFinished.dispatch();
				return error.response;

			}

		},
		saveProject: async (onProgress = undefined) => {

            const zipped = await ExportUtils.generateProjectZip(editor);
            const blob = new Blob( [ zipped.buffer ], { type: 'application/zip' } );

            const workspaceId = config.getKey( 'user/workspace' );
            const projectId = config.getKey( 'project/id' );

            console.assert(workspaceId && typeof workspaceId === 'string', `saveProject() missing 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(projectId && typeof projectId === 'string', `saveProject() missing 'projectId'.  Found '${projectId}'`);

            // get upload url
            const { post } = useApi( '/projects/createUploadURL', true );
            const data = await post( { workspaceId, projectId, extention: 'zip' } );

            const formData = new FormData();
            for (const key in data.fields) {
              formData.append(key, data.fields[key]);
            }
            formData.append('file', blob);

            const xhr = new XMLHttpRequest();
            xhr.open('POST', data.url, true);

            if (onProgress) {
                xhr.upload.addEventListener('progress', onProgress);
            }

            await new Promise((res, rej) => {
                xhr.addEventListener('load', () => {
                    if (xhr.status === 204) {
                        res(xhr);
                    } else {
                        rej(xhr);
                    }
                });
                xhr.send(formData);
            })

            signals.ejxSaveFinished.dispatch();

		},
		createPreview: async ( workspaceId, projectId, versionId, createdBy ) => {

            console.assert(workspaceId && typeof workspaceId === 'string', `createPreview() incorrect parameter for 'workspaceId'.  Found '${workspaceId}'`);
            console.assert(projectId && typeof projectId === 'string', `createPreview() incorrect parameter for 'projectId'.  Found '${projectId}'`);
            console.assert(versionId && typeof versionId === 'string', `createPreview() incorrect parameter for 'versionId'.  Found '${versionId}'`);
            console.assert(createdBy && typeof createdBy === 'string', `createPreview() incorrect parameter for 'createdBy'.  Found '${createdBy}'`);

			try {

				const body = {
					workspaceId,
					projectId,
					versionId,
					createdBy
				};
				const { post } = useApi( '/previews', true );
				const data = await post( body );

				return data;


			} catch ( error ) {

				console.error( error );
                throw error;

			}

		},

        /**
         * Gets the latest published version of a project.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @returns {Record<string, unknown>|undefined} Published artwork
         */
        async getPublished(workspaceId, projectId) {
            // return Promise.resolve({
            //     creationDate: 1708906022721,
            //     version: '01HQHB...',
            //     keyPath: 'u/connor-meehan/project-id/published-01HQHB.../',
            // })
            const api = useApi(`/projects/${workspaceId}/${projectId}/published`, true);
            const response = await api.get();
            return response[0];
        },

        /**
         * Publishes a version of a project.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} versionId 
         */
        async putPublished(workspaceId, projectId, versionId) {
            // return Promise.resolve({
            //     creationDate: 1708906022721,
            //     version: '01HQHB...',
            //     keyPath: 'u/connor-meehan/project-id/published-01HQHB.../',
            // })
            const api = useApi(`/projects/${workspaceId}/${projectId}/published/${versionId}`, true);
            const response = await api.put();
            return response;
        },

        /**
         * Unpublishes a project version.
         * @param {string} workspaceId 
         * @param {string} projectId 
         * @param {string} versionId 
         */
        async deletePublished(workspaceId, projectId, versionId) {
            const api = useApi(`/projects/${workspaceId}/${projectId}/published/${versionId}`, true);
            const response = await api.delete();
            return response;
        },

        /**
         * Refreshes the capabilities of a user. Capabilities are stored in `config.getKey('user/capabilities')`
         *
         * @param {string} userId 
         * @returns {string[]} 
         */
        async refreshUserCapabilities(userId = config.getKey('user/username') ) {
            const api = useApi(`/users/${userId}/capabilities`, true)
            const data = await api.get();
            config.setKey('user/capabilities', data);

            const customProjectSizeCapability = data.find(cap => cap.capability === 'max-project-size');
            if (customProjectSizeCapability) {
                config.setKey('user/maxProjectSizeMb', customProjectSizeCapability.extraData.sizeMb);
            }

            signals.userCapabilitiesChanged.dispatch(data);
            return data;
        }

	};

}

export { EJXAPI };
