import { UIDiv, UIPanel, UIRow, UIText } from './libs/ui.js';

import { createEJXButton, createHeading } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES, EJX_CDN_URL } from './Config.EJX.js';
import unzipFile from './libs/unzip-file.js';
import { pushNotification } from './EJXNotification.js';
import deleteButtonImage from '../images/delete.png';
import { easeOutCubic } from './utils/math.js';
import { ImportUtils } from './utils/importexport.js';
import { ConfirmationOverlayCancelError, showConfirmationOverlay } from './Overlay.js';
import { captureException } from '@sentry/astro';

const getProjectLatestVersion = (project) => {
    if (project.versions.length === 0) return null;
    return project.versions.reduce((acc, el) => el.creationDate > acc.creationDate ? el : acc, project.versions[0]);
}

function ProjectElement( editor, project ) {
    const signals = editor.signals;
    const ejxAPI = editor.ejxAPI;
    const config = editor.config;

    const container = document.createElement('li');
    container.classList.add('ProjectElement');
    const id = `load-${project.projectId}`;

    const input = document.createElement('input');
    container.append(input)
    input.classList.add('ProjectElement_Input');
    input.setAttribute('type', 'radio');
    input.setAttribute('data-project-id', project.projectId);
    input.id = id;

    const label = document.createElement('label');
    container.append(label);
    label.setAttribute("for", id);
    label.style.flex = '1 1 100%';
    label.style.minWidth = '0';

    const title = document.createElement('h4');
    label.append(title);
    title.style.width = '100%';
    title.style.overflow = 'hidden';
    title.style.textOverflow = 'ellipsis';
    title.innerText = project.name;

    const slug = document.createElement('h5');
    label.append(slug);
    slug.style.width = '65%';
    slug.style.overflow = 'hidden';
    slug.style.textOverflow = 'ellipsis';
    slug.innerText = project.projectId;

    if (project.versions && project.versions.length > 0) {
        const lastVersion = getProjectLatestVersion(project);
        const creationDate = new Date(Number.parseInt(lastVersion.creationDate));
        const el = document.createElement('h5');
        el.classList.add('ProjectElement_LastVersion');
        container.append(el);
        el.innerText = creationDate.toLocaleString();
    }

    const deleteButton = createEJXButton('Delete', () => {
        if(confirm(`Are you sure you want to delete ${project.name}?`)) {
            ejxAPI.deleteProject(config.getKey( 'user/workspace' ), project.projectId);
        }
    });
    deleteButton.innerHTML = '';
    const deleteImage = document.createElement('img');
    deleteImage.src = deleteButtonImage.src;
    deleteImage.style.height = '14px';
    deleteImage.style.width = '14px';
    deleteButton.appendChild(deleteImage);
    deleteButton.classList.add('delete-project-button');

    container.append(deleteButton);

    return container;
}

function ProjectList( editor, projects ) {
    const container = new UIDiv();

    const list = document.createElement('ul');
    list.classList.add('ProjectList');
    container.dom.append(list);
    list.role = 'listbox';

    for (const p of projects) {
        const el = new ProjectElement(editor, p);
        list.append(el);
    }

    let projectId = projects[0].projectId;
    const handleClick = (e) => {
        const targetProjectId = e.target && e.target.getAttribute('data-project-id');
        if (targetProjectId) {
            projectId = targetProjectId;
            updateSelection();
        }
    }

    const updateSelection = () => {
        const inputEls = list.querySelectorAll('.ProjectElement_Input');
        for (const el of inputEls) {
            const elProjectId = el.getAttribute('data-project-id');
            if (projectId && elProjectId === projectId) {
                el.checked = true
                el.parentElement.classList.add('ProjectElement__active')
            } else {
                el.checked = false;
                el.parentElement.classList.remove('ProjectElement__active')
            }
        };
    }
    updateSelection();

    list.addEventListener('click', handleClick);

    container.dispose = () => {
        list.removeEventListener('click', handleClick);
    }

    container.getValue = () => {
        return projectId;
    }

    return container;
}

function DialogOpen( editor ) {

	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

    let projectList;
	const populateProjectSelectMenu = ( projects ) => {
        if (projectList) {
            projectList.dispose();
            for (const el of projectSelectMenu.children) {
                el.remove()
            }
        }
        if (projects.length) {
            const sorted = projects.sort((a, b) => {
                const latestVersionA = getProjectLatestVersion(a);
                const latestVersionB = getProjectLatestVersion(b);
                const creationDateA = Number.parseInt(latestVersionA ? latestVersionA.creationDate : 0);
                const creationDateB = Number.parseInt(latestVersionB ? latestVersionB.creationDate : 0);
                return creationDateB - creationDateA;
            })

            projectList = new ProjectList( editor, projects );
            projectSelectMenu.append(projectList.dom);
        } else {
            const warnEl = document.createElement('h4');
            warnEl.innerText = 'No projects to load yet.  Start creating and then save with File > Save to EJX Cloud.';
            warnEl.style.margin = '20px';
            projectSelectMenu.append(warnEl)
        }

	};

	const onSelectProject = async () => {

        const projectId = projectList && projectList.getValue();

        if (!projectId) {
            pushNotification(editor, {
                type: 'error',
                title: 'Please pick a project from the list.',
            })
            return;
        }

		signals.setLoadingStatus.dispatch( 'Downloading project...' );
		signals.loadingStarted.dispatch();
		try {

			container.dom.removeChild( projectScreen );
			container.dom.appendChild( openLoadingScreen );

			const latestProjectVersion = await ejxApi.getLatestVersion( config.getKey( 'user/workspace' ), projectId );
			const latestVersionUrl = `${EJX_CDN_URL}/${latestProjectVersion.keyPath}`;

            const zip = await new Promise((res, rej) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', latestVersionUrl, true);
                xhr.responseType = 'blob';
    
                xhr.onprogress = (event) => {
                    const progress = Math.round(100 * easeOutCubic(event.loaded / event.total));
                    signals.setLoadingStatus.dispatch( `Downloading project... ${progress}%` );
                }
    
                xhr.onload = () => {
                    res(xhr.response);
                }
    
                xhr.onerror = (event) => {
                    console.error(event);
                    rej();
                }
    
                xhr.send();
            });
            
            if(!zip) return;

            editor.signals.loadingFinished.dispatch();

            await showConfirmationOverlay( editor, {
                title: 'Any unsaved data will be lost.  Are you sure?',
                confirmText: 'Yes',
                cancelText: 'No',
            })

            try {
                await ImportUtils.loadFromZip(editor, new Uint8Array(await zip.arrayBuffer()));
                editor.config.setKey('project/id', projectId);
                editor.signals.projectLoaded.dispatch();
            } catch (reason) {
                console.error(reason);
                pushNotification(editor, {
                    type: 'error',
                    title: reason.message,
                    timeout: 100000,
                })
            } finally {
                signals.closeDialog.dispatch();
            }

            signals.unsetProjectChanged.dispatch();
		} catch ( error ) {

            if (error instanceof ConfirmationOverlayCancelError) {
                // Ignore user cancel
            } else {
                let message;
                if (error instanceof Error) {
                    message = error.message;
                } else if (error.toString) {
                    message = error.toString();
                } else {
                    message = JSON.stringify(error);
                }

                captureException(error);
                console.error(error);
                pushNotification(editor, {
                    type: 'error',
                    title: 'There was an error opening the project.',
                    description: message,
                })
            }
		}


		signals.loadingFinished.dispatch();

	};

    const updateProjects = async () => {
        signals.setLoadingStatus.dispatch( 'Fetching projects...' );
        signals.loadingStarted.dispatch();
        try {
            const projectList = await ejxApi.getProjects( config.getKey( 'user/workspace' ), config.getKey( 'user/username' ) );
            config.setKey( 'user/projectList', projectList );
            if ( projectList.length === 0 ) {

                // show no projects screen
                projectScreen.remove();
                const noProjectsScreen = document.createElement( 'div' );
                noProjectsScreen.style.textAlign = 'center';
                const noProjectsMessage = new UIText( 'You haven\'t saved any projects yet' );
                noProjectsMessage.dom.style.margin = '20px auto';
                noProjectsMessage.dom.style.color = '#C0FB00';
                noProjectsScreen.appendChild( noProjectsMessage.dom );
                container.dom.appendChild( noProjectsScreen );

            } else {

                populateProjectSelectMenu( projectList );

            }
        } catch (error) {
            console.log(error);
        } finally {
            signals.loadingFinished.dispatch();
        }
    }

	signals.launchDialog.add( async ( screenState ) => {

		if ( screenState === DIALOG_SCREEN_STATES.OPEN && config.getKey( 'auth/isLoggedIn' ) ) {

            projectSelectMenu.style.display = 'none';
            projectSelectButton.style.visibility = 'hidden';
            await updateProjects();
            projectSelectMenu.style.display = '';
            projectSelectButton.style.visibility = 'visible';

		}

	} );

    signals.projectDeleted.add(updateProjects);

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		container.dom.appendChild( projectScreen );


	} );


	const projectScreen = document.createElement( 'div' );
	projectScreen.appendChild( createHeading( 'Open EJX Project' ) );
	const projectSelectMenu = document.createElement( 'div' );
	projectScreen.appendChild( projectSelectMenu );
	const projectSelectButton = createEJXButton( 'Open EJX project', async () => onSelectProject() )
	projectScreen.appendChild( projectSelectButton );

	const openLoadingScreen = document.createElement( 'div' );
	openLoadingScreen.style.textAlign = 'center';
	openLoadingScreen.appendChild( createHeading( 'Loading...' ) );

	container.dom.appendChild( projectScreen );

	return container;

}

export { DialogOpen };
