import { DIALOG_SCREEN_STATES, EJX_SIGN_UP_URL } from './Config.EJX.js';
import { SidebarEJXProjectSize } from './Sidebar.EJX.ProjectSize.js';
import { SidebarEJXPublish } from './Sidebar.EJX.Publish.js';
import { UIDiv, UIPanel, UIText } from './libs/ui.js';
import { createEJXButton } from './utils.EJX.js';

function SidebarEJX( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxAPI = editor.ejxAPI;

	let unsavedChanges = false;

	const container = new UIPanel();
	container.setId( 'ejxSidebar' );

    const projectSize = new SidebarEJXProjectSize( editor );
    container.add(projectSize);

	const unsavedChangesMessage = 'Unsaved changes:  Save before playing in XR.';
	const savedChangesMessage = 'Saved changes:  Ready to play in XR.';

	const onProjectStateChange = () => {
		if ( ! unsavedChanges ) {

			unsavedChanges = true;
			ejxPanelText.setTextContent( unsavedChangesMessage );
		}
	};

	const onSave = () => {

		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.SAVING );
		//ejxAPI.saveProject();

	};


	signals.geometryChanged.add( onProjectStateChange );
	signals.objectAdded.add( onProjectStateChange );
	signals.objectChanged.add( onProjectStateChange );
	signals.objectRemoved.add( onProjectStateChange );
	signals.materialChanged.add( onProjectStateChange );
	// Background / environment will be re-added after cubes.
	//
	// signals.sceneBackgroundChanged.add( onProjectStateChange );
	// signals.sceneEnvironmentChanged.add( onProjectStateChange );
	signals.sceneFogChanged.add( onProjectStateChange );
	signals.sceneGraphChanged.add( onProjectStateChange );
	signals.scriptChanged.add( onProjectStateChange );
	signals.historyChanged.add( onProjectStateChange );

	const ejxPanel = new UIPanel();
    container.add(ejxPanel);
	const ejxPanelText = new UIText( unsavedChangesMessage );
	ejxPanelText.dom.style.margin = '0 10px';
	ejxPanelText.dom.style.fontSize = '12px';

	const mint = () => {
		const sceneBoundingBox = new THREE.Box3().setFromObject(editor.scene);
		const sceneCenter = new THREE.Vector3();
		if(config.getKey('project/type') === 'Default') sceneBoundingBox.getCenter(sceneCenter);
		const sceneSize = new THREE.Vector3(1, 1, 1);
		if(config.getKey('project/type') === 'Default') sceneBoundingBox.getSize(sceneSize);

		const shotsToRequest = [
			{
				position: sceneCenter.clone().add(new THREE.Vector3(3 * sceneSize.x, 3 * sceneSize.y, 3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(-3 * sceneSize.x, 3 * sceneSize.y, 3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(-3 * sceneSize.x, 3 * sceneSize.y, -3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(3 * sceneSize.x, 3 * sceneSize.y, -3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
		];

		if(config.getKey('project/type') === 'Default') {
			shotsToRequest.push({
				position: sceneCenter.clone().add(new THREE.Vector3(3 * sceneSize.x, 0, 3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(3.5 * sceneSize.x, 0, 0)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(3 * sceneSize.x, 0, -3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(0, 0, -3.5 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(-3 * sceneSize.x, 0, -3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(-3.5 * sceneSize.x, 0, 0)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(-3 * sceneSize.x, 0, 3 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			},
			{
				position: sceneCenter.clone().add(new THREE.Vector3(0, 0, 3.5 * sceneSize.z)),
				target: sceneCenter,
				width: config.getKey('minter/thumbnail/width'),
				height: config.getKey('minter/thumbnail/height'),
				fov: 20,
				zoom: config.getKey('minter/thumbnail/zoom')
			});
		}
		signals.requestPlayerThumbnail.dispatch( shotsToRequest );

		signals.respondPlayerThumbnail.addOnce(() => { signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.THUMBNAIL); } );
	};

    // UI for when in the editor mode
    const editorModeContainer = new UIDiv();
    editorModeContainer.setId('sidebar-ejx-editor-container');
    ejxPanel.add(editorModeContainer);
	const ejxPlayPreviewButton = createEJXButton('Play', () => signals.startPlayer.dispatch(), 'primary');
    editorModeContainer.dom.append(ejxPlayPreviewButton);

    // UI for when in the preview play mode
    const playPreviewModeContainer = new UIDiv();
    playPreviewModeContainer.setId('sidebar-ejx-play-preview-container');
    playPreviewModeContainer.setVisible(false);
    ejxPanel.add(playPreviewModeContainer);

    // 
    const ejxPublishContainer = new SidebarEJXPublish(editor);
	const ejxPlayButton = createEJXButton('Stop Playing', () => signals.stopPlayer.dispatch(), 'primary');
	const ejxMintButton = createEJXButton(strings.getKey('menubar/file/mint'), mint, 'secondary');
	// ejxMintButton.disabled = true;
	playPreviewModeContainer.dom.appendChild( ejxPanelText.dom );
	playPreviewModeContainer.add( ejxPublishContainer );
	playPreviewModeContainer.dom.appendChild( ejxPlayButton );
	playPreviewModeContainer.dom.appendChild( ejxMintButton );


	const ejxLogoutButton = createEJXButton( 'Logout', () => {
        signals.showOverlay.dispatch({
            title: 'Are you sure you want to logout?',
            buttons: [
                { text: 'Logout', type: 'primary', action: () => {
                    signals.logout.dispatch();
                    signals.hideOverlay.dispatch();
                } },
            ]
        })
    }, 'tertiary' );
	ejxPanel.dom.appendChild( ejxLogoutButton );

    const loggedInAsText = document.createElement('p')
    loggedInAsText.innerText = `Logged in as`;
    loggedInAsText.style.textAlign = 'center';
    const usernameText = document.createElement('span');
    usernameText.style.color = '#ccc';
    usernameText.style.margin = '0 3px';
    loggedInAsText.appendChild(usernameText);
	ejxPanel.dom.appendChild( loggedInAsText );
    const updateLoginText = () => {
        const username = config.getKey('user/username');
        usernameText.innerText = username;
    }
    signals.loginSuccess.add(updateLoginText);
    updateLoginText();

	signals.startPlayer.add(function () {
        editorModeContainer.setVisible(false);
        playPreviewModeContainer.setVisible(true);
	});

	signals.stopPlayer.add(function () {
        editorModeContainer.setVisible(true);
        playPreviewModeContainer.setVisible(false);
	});

	signals.ejxSaveFinished.add( function () {

		ejxPanelText.setTextContent( savedChangesMessage );
		unsavedChanges = false;

	} );

    /**
     * Login related methods
     */
	const ejxLoginPanel = new UIPanel();
    container.add(ejxLoginPanel);
	const ejxLoginText = new UIText( 'Login or Signup to access EJX Cloud features.' );
	ejxLoginText.dom.style.margin = '0 10px';
	ejxLoginText.dom.style.fontSize = '12px';

	const ejxLoginButton = createEJXButton( 'Log in', () => signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.LOGIN), 'primary' );
	const ejxSignupButton = createEJXButton( 'Sign up', () => signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.SIGN_UP), 'secondary' );

	ejxLoginPanel.dom.appendChild( ejxLoginText.dom );
	ejxLoginPanel.dom.appendChild( ejxLoginButton );
	ejxLoginPanel.dom.appendChild( ejxSignupButton );

	const handleLoginChange = () => {
		const isLoggedIn = config.getKey('auth/isLoggedIn');
		if (isLoggedIn) {
		    ejxLoginPanel.setVisible(false);
		    ejxPanel.setVisible(true);
		} else {
		    ejxLoginPanel.setVisible(true);
		    ejxPanel.setVisible(false);
		}
	}
	signals.loginSuccess.add(handleLoginChange);
	signals.logoutSuccess.add(handleLoginChange);
	handleLoginChange();

	container.dom.appendChild( config.getKey( 'auth/isLoggedIn' ) ? ejxPanel.dom : ejxLoginPanel.dom );

	signals.startPlayer.add(function () {
		ejxMintButton.disabled = false;
	});

	signals.stopPlayer.add(function () {
		ejxMintButton.disabled = true;
	});

	signals.ejxSaveFinished.add( function () {

		ejxPanelText.setTextContent( savedChangesMessage );
		unsavedChanges = false;

	} );

	return container;

}

export { SidebarEJX };
