import { UIInput } from './libs/ui.js';

const createEJXButton = ( text, handler, type = 'primary', styles = '' ) => {

	const buttonEl = document.createElement( 'button' );
	buttonEl.textContent = text;
	buttonEl.type = 'button';
	buttonEl.className = type;
	buttonEl.addEventListener( 'click', handler );
	return buttonEl;

};

const createHeading = ( text ) => {

	const headingEl = document.createElement( 'h3' );
	headingEl.textContent = text;
	return headingEl;

};

const createInput = ( type, id, placeholder ) => {

	const inputEl = new UIInput( '' );
	inputEl.dom.type = type;
	inputEl.dom.id = id;
	inputEl.dom.style.display = 'block';
	inputEl.dom.style.width = '178px';
	inputEl.dom.style.margin = '10px 0';
	inputEl.dom.style.padding = '10px';
	inputEl.dom.placeholder = placeholder;
	return inputEl.dom;

};

const createForm = ( formElements ) => {

	const formEl = document.createElement( 'form' );
	formEl.onsubmit = ( e ) => e.preventDefault();
	formEl.style.display = 'block';
	formEl.style.margin = '20px auto';
	formEl.style.width = '200px';
	formElements.forEach( ( formElement ) => {

		formEl.appendChild( formElement );

	} );

	return formEl;

};

const kebabCase = string => string
	.replace( /([a-z])([A-Z])/g, '$1-$2' )
	.replace( /[\s_]+/g, '-' )
	.toLowerCase();

export {

	createEJXButton,
	createHeading,
	createInput,
	createForm,
	kebabCase

};
