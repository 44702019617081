import { UIPanel, UIRow, UIInput, UICheckbox, UIText, UISpan, UISelect, UIDiv } from './libs/ui.js';

/* import { SidebarProjectMaterials } from './Sidebar.Project.Materials.js'; */
import { ProjectTypeParametersPanel } from './Sidebar.Project.ProjectType.EJXCube.js';
import { SidebarProjectRenderer } from './Sidebar.Project.Renderer.js';
import { SidebarProjectVideo } from './Sidebar.Project.Video.js';
import { SidebarProjectPostprocessing } from './Sidebar.Project.Postprocessing.js';

function SidebarProject( editor ) {

	const config = editor.config;
	const signals = editor.signals;
	const strings = editor.strings;

	const container = new UISpan();

	const settings = new UIPanel();
	settings.setBorderTop( '0' );
	settings.setPaddingTop( '20px' );
	container.add( settings );

	// Title

	const titleRow = new UIRow();
	const title = new UIInput( config.getKey( 'project/title' ) ).setLeft( '100px' ).setWidth( '150px' ).onChange( function () {

		config.setKey( 'project/title', this.getValue() );

	} );

	signals.loadingFinished.add( function () {
		title.setValue( config.getKey( 'project/title' ) );
	} );

	titleRow.add( new UIText( strings.getKey( 'sidebar/project/title' ) ).setWidth( '90px' ) );
	titleRow.add( title );

	settings.add( titleRow );

	// Extra options panel

	const projectOptions = new UIDiv();
	container.add( projectOptions );

	const buildProjectTypeOptions = () => {
		projectOptions.clear();
		switch( config.getKey( 'project/type' ) ){
			case 'EJXCube':
				projectOptions.add( new ProjectTypeParametersPanel(editor));
				break;
		}
	};

	buildProjectTypeOptions();

	signals.switchedProjectType.add( buildProjectTypeOptions );
	signals.loadingFinished.add( buildProjectTypeOptions );
	signals.editorCleared.add( buildProjectTypeOptions );

	/* container.add( new SidebarProjectMaterials( editor ) ); */
	container.add( new SidebarProjectRenderer( editor ) );

	if ( 'SharedArrayBuffer' in window ) {

		container.add( new SidebarProjectVideo( editor ) );

	}

	container.add( new SidebarProjectPostprocessing( editor ) );

	// Signals

	signals.editorCleared.add( function () {

		title.setValue( '' );
		config.setKey( 'project/title', '' );

	} );

	return container;

}

export { SidebarProject };
