import { LinearSRGBColorSpace, SRGBColorSpace } from "three";
import { traverseAppJsonObject } from "../utils/importexport";

/**
 * Stores 
 * methods to migrate appJson to the latest version.
 * The key is is current version number and the value is a function that mutates the appJson structure, updating it to the latest version.
 * */
export const Migrations = {
    /**
     * Migrates from a default threejs editor project to an EJX editor project
     * @param {any} appJson 
     */
    [0](appJson) {
        const project = appJson.project;
        if (project.postprocessing && project.postprocessing.length > 0) {
            project.postprocessing = project.postprocessing.map((passConfig => {
                // Bloom pass config key names have changed.
                if (passConfig.type === 'bloom') {
                    return {
                        type: 'bloom',
                        threshold: passConfig.bloomThreshold || passConfig.threshold || 0.05,
                        strength: passConfig.bloomStrength || passConfig.strength || 0.25,
                        radius: passConfig.bloomRadius || passConfig.radius || 0.2,
                        exposure: passConfig.bloomExposure || passConfig.exposure || 1,
                    }
                }
                return passConfig;
            }))
        }
        return appJson;
    },

    /**
     * Migration for updating from three.js r151 -> r162
     * most notably these changes https://github.com/mrdoob/three.js/wiki/Migration-Guide#152--153
     * @param {any} appJson 
     */
    [1](appJson) {
        if (appJson.project && appJson.project.useLegacyLights !== undefined) {
            delete appJson.project.useLegacyLights;
        }

        const textures = appJson.scene.textures;

        // https://github.com/mrdoob/three.js/blob/70cc4e192fe2ebd0bf8542a81c8c513d61984c58/src/constants.js#L143
        const valueMap = {
            // LinearEncoding -> LinearSRGBColorSpace
            [3000]: 'srgb-linear',
            // sRGBEncoding -> SRGBColorSpace
            [3001]: 'srgb',
        }

        if (textures) {
            for (const tex of textures) {
                if (!tex.encoding) continue;

                const value = valueMap[tex.encoding];
                tex.colorSpace = value;

                if (import.meta.env.DEV) console.debug(`[1->2] Updated encoding -> colorspace from ${tex.encoding} to ${tex.colorSpace}`);
                delete tex.encoding;
            }
        }

        return appJson;
    }
}
