import { UIPanel, UIText } from './libs/ui.js';

import { createEJXButton, createHeading, createInput, createForm } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { pushNotification } from './EJXNotification.js';

const MIN_PROJECT_NAME_LENGTH = 1;
const MAX_PROJECT_NAME_LENGTH = 64;

function DialogSaveAs( editor ) {

	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		errorMessage.setValue( '' );
		saveProjectAsInput.value = '';
		saveProjectAsButton.disabled = true;
		container.dom.appendChild( saveProjectAsScreen );

	} );

	const validateInput = () => {
		const validLength = (saveProjectAsInput.value.length >= MIN_PROJECT_NAME_LENGTH && saveProjectAsInput.value.length <= MAX_PROJECT_NAME_LENGTH);
		if(validLength) {
			saveProjectAsButton.disabled = false;
			saveProjectAsInput.style.border = '';
			saveProjectAsInput.style.backgroundColor = '';
		} 
		else {
			saveProjectAsButton.disabled = true;
			saveProjectAsInput.style.border = '1px solid #800';
			saveProjectAsInput.style.backgroundColor = '#8002';
			pushNotification(editor, {
            	type: 'error',
            	title: 'Invalid name length',
            	description: `Name must be at least ${MIN_PROJECT_NAME_LENGTH} and no more than ${MAX_PROJECT_NAME_LENGTH} characters.`
        	});
		}
		return (validLength);
	}

	const saveProjectAsScreen = document.createElement( 'div' );
	saveProjectAsScreen.style.display = 'flex';
	saveProjectAsScreen.style.flexDirection = 'column';
	saveProjectAsScreen.style.alignItems = 'center';
	saveProjectAsScreen.appendChild( createHeading( 'Save your Project as...' ) );
	const saveProjectAsText = new UIText( 'Must choose a unique project name' );
	saveProjectAsScreen.appendChild( saveProjectAsText.dom );
	const saveProjectAsInput = createInput( 'text', 'projectName', 'Project Name' );
	saveProjectAsInput.maxLength = MAX_PROJECT_NAME_LENGTH + 1;
	saveProjectAsInput.addEventListener( 'input', validateInput );

	const saveProjectAsButton = createEJXButton( 'Save Project', async ( e ) => {

		if(!validateInput()) return;

		e.preventDefault();
		signals.setLoadingStatus.dispatch( 'Saving...' );
		signals.loadingStarted.dispatch();

		const newProjectTitle = document.getElementById( 'projectName' ).value;
        let response;
        try {
            response = await ejxApi.createProject( config.getKey( 'user/workspace' ), config.getKey( 'user/username' ), newProjectTitle );
        } catch(err) {
            errorMessage.setValue( err.response.data.message );
            signals.loadingFinished.dispatch();
            return;
        }

        signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.SAVING );
        config.setKey(
            'project/id', response.data.projectId,
            'project/title', response.data.name,
        );


	}, 'primary' );
	saveProjectAsButton.type = "submit";
	saveProjectAsButton.disabled = true;
	const errorMessage = new UIText( '' );
	errorMessage.setStyle( 'color', [ '#FF4444' ] );
	errorMessage.setStyle( 'font-size', [ '14px' ] );
	const saveProjectAsForm = createForm( [ saveProjectAsInput, errorMessage.dom, saveProjectAsButton ] );
	saveProjectAsForm.style.display = 'flex';
	saveProjectAsForm.style.flexDirection = 'column';
	saveProjectAsForm.style.alignItems = 'center';
	saveProjectAsForm.style.marginTop = '10px';
	saveProjectAsScreen.appendChild( saveProjectAsForm );

	container.dom.appendChild( saveProjectAsScreen );

	return container;

}

export { DialogSaveAs };
