import { UIPanel } from './libs/ui.js';

import SpinnerImg from '../images/spinner.png';
import { createHeading } from './utils.EJX.js';

function DialogAbout( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

    const heading = createHeading('About');
    container.dom.append(heading);

    let p = document.createElement('p');
    p.innerText = `Editor ${__BUILD_PKG_VERSION__}
    Build Date: ${__BUILD_DATE__}
    Sha: ${__BUILD_SHA__}
    `;
    container.dom.append(p);

    const seperator = document.createElement('div');
    seperator.style.borderBottom = '1px solid #777'; 
    seperator.style.margin = '10px 0';
    container.dom.append(seperator);

    p = document.createElement('p');
    p.innerText = `THREE: ${__DEP_THREE_VERSION__}
    EJX Lib: ${__DEP_EJX_VERSION__}`;
    container.dom.append(p);


	return container;

}

export { DialogAbout };
