import { UIDiv, UISpan } from "./libs/ui";
import { zipSync, unzip, strToU8 } from 'fflate';
import { ExportUtils } from "./utils/importexport";

const COLOR_BREAKPOINTS = { 
    [0]: '#C0FB00',
    [0.5]: '#FB8100',
    [0.9]: '#E32D10', 
    [1]: '#FF0000',
};
const getColorForBytes = (bytes, maxBytes) => {
    let pickedColor;
    const sizeFloat = bytes / maxBytes; 
    for (const [size, color] of Object.entries(COLOR_BREAKPOINTS)) {
        const breakpoint_bytes = Number.parseInt(size);
        if (sizeFloat > breakpoint_bytes) {
            pickedColor = color;
        }
    }

    return pickedColor;
}

let templateSize;

const formatBytes = (bytes) => {
    const megabytes = bytes / 1024 / 1024;
    return `${megabytes.toFixed(2)}MB`;
}

function SidebarEJXProjectSize (editor) {
    const signals = editor.signals;

    const container = new UIDiv();
    container.setId('project-size');
    container.setStyle('margin', [ '0px 10px 10px' ]);
    container.setStyle('display', [ 'flex' ]);
    container.setStyle('align-items', [ 'center' ]);
    container.setStyle('flex-wrap', [ 'no-wrap' ]);
    container.setStyle('gap', [ '5px' ]);
    container.setStyle('justify-content', ['center']);
    container.setStyle('align-items', ['center'])

    const text = new UISpan();
    text.setStyle('flex', [ '0 1 auto' ]);
    text.setStyle('height', [ '18px' ]);
    text.setStyle('line-height', [ '1' ]);
    text.setStyle('order', ['1']);
    text.setStyle('padding', [ '2px' ]);
    text.setStyle('font-size', ['14px']);
    container.add(text);

    const barContainer = new UIDiv();
    barContainer.setStyle('border', [ '1px solid #999' ]);
    barContainer.setStyle('padding', [ '1px' ]);
    barContainer.setStyle('height', [ '16px' ]);
    barContainer.setStyle('flex', [ '1 1 95px' ]);
    container.add(barContainer);

    const bar = new UIDiv();
    bar.setStyle('height', [ '12px' ]);
    barContainer.add(bar);

    // Stores the size of the project in bytes
    let latestSizeBytes = undefined;
    const render = () => {
        const maxMb = editor.config.getKey('user/maxProjectSizeMb') || 50;
        const maxBytes = maxMb * 1024 * 1024;

        if (latestSizeBytes === undefined) {
            text.dom.style.color = "#888";
            text.dom.innerText = `? MB / ${formatBytes(maxBytes)}`;
            bar.setStyle('width', ['100%']);
            bar.setStyle('background-color', [ '#888 ']);
        } else {
            text.dom.innerText = `${formatBytes(latestSizeBytes)} / ${formatBytes(maxBytes)}`;
            barContainer.setStyle('opacity', ['1']);
            bar.setStyle('background-color', [ getColorForBytes(latestSizeBytes, maxBytes)]);
            if(latestSizeBytes > maxBytes) {
                text.dom.style.color = "#FF0000";
            }
            else {
                text.dom.style.color = "#888";
            }
            const barWidth = Math.min(maxBytes, latestSizeBytes) / maxBytes
            bar.setStyle('width', [`${(barWidth * 100).toFixed(1)}%`])
        }
    }

    render();

    const handleSizeChanged = (size) => {
        if(!templateSize) {
            setTimeout(() => handleSizeChanged(size), 500);
        }
        else {
            latestSizeBytes = templateSize + size;
            render();
        }
    }

    container.add(barContainer);

    signals.localSaveFinished.add(handleSizeChanged);
    signals.objectAdded.add(render);
    signals.objectRemoved.add(render);
    signals.editorCleared.add(render);
    signals.materialAdded.add(render);
    signals.materialRemoved.add(render);
    signals.geometryChanged.add(render);
    signals.userCapabilitiesChanged.add(() => {
         // Delay to let config store update
        setTimeout(render, 1);
    });

    const fetchTemplateSize = async () => {
        const zipData = await ExportUtils.getUnzippedTemplate( editor );

        let tempTemplateSize = 0;
        for(const key in zipData) {
            tempTemplateSize += zipData[key].length;
        }
        templateSize = tempTemplateSize;
    }

    fetchTemplateSize();

    return container;
}

export { SidebarEJXProjectSize, templateSize };
