import { UIPanel } from './libs/ui.js';
import { EJPlayer, EJPlayerBase } from '@eyejack/ejx';
import { canvasToFile, makeCroppedCanvas, makeResizedCanvas } from './utils/image.js';
import { XRButton } from './Player.EJX.XRButton.js';
import { ThreeMFLoader } from 'three/examples/jsm/loaders/3MFLoader.js';
import { Color } from 'three';

function Player( editor ) {
	const config = editor.config;
	const signals = editor.signals;

	const container = new UIPanel();
	container.setId( 'player' );
	container.setPosition( 'absolute' );
	container.setDisplay( 'none' );

	const determinePlayer = () => {
		const projectType = editor.config.getKey( 'project/type' );
		switch( projectType ) {
			case 'Default':
				return new EJPlayerBase();
			case 'EJXCube':
				const cubePlayer = new EJPlayer();
				cubePlayer.setCube( 'Plain' );
				cubePlayer.setEnvironment( 'None' );
				return cubePlayer;
		}
	};

	let player = determinePlayer();
	
	container.dom.appendChild( player.renderer.domElement );

	editor.signals.switchedProjectType.add(() => {
		container.dom.removeChild( player.renderer.domElement );
		player = determinePlayer();
		container.dom.appendChild( player.renderer.domElement );
	})

    const xrButton = new XRButton(editor, player);
    container.add(xrButton);

	const playerShortcuts = (event) => {
		if(event.key === "Escape") {
			event.preventDefault();
			signals.stopPlayer.dispatch();
		}
	}

	const enableShortcuts = () => {
		if(!player.shortcutsEnabled){
			player.shortcutsEnabled = true;
			document.addEventListener('keydown', playerShortcuts);
		}
	}

	const disableShortcuts = () => {
		if(player.shortcutsEnabled){
			player.shortcutsEnabled = false;
			document.removeEventListener('keydown', playerShortcuts);
		}
	}

	signals.loadingStarted.add(disableShortcuts);
	signals.loadingFinished.add(disableShortcuts);

    let started = false;
	const startPlayer = () => {
        started = true;
		container.setDisplay( '' );
		container.setStyle('background-color', ['black']);

		import('./libs/ejx/app.js').then(module => {
			player.setContent({filename: './libs/ejx/app.js', module});
			player.contentCurrent().instance.load( structuredClone(editor.toJSON()) );
		});

		resize(); // player dimensions are smaller then fullscreen and need to be updated.

		enableShortcuts();
	}

	const stopPlayer = () => {
		disableShortcuts();
        started = false;
		container.setDisplay( 'none' );
		player.contentKillAll();
	}

	const render = (timestamp, frame) => {
		player.render();
	}

	const resize = () => {
		player.resize( container.dom.clientWidth, container.dom.clientHeight, window.devicePixelRatio );
	}

	player.renderOverrideFunc = render.bind(this);
	player.resizeOverrideFunc = resize.bind(this);

	signals.startPlayer.add( startPlayer.bind(this) );
	signals.stopPlayer.add( stopPlayer.bind(this) );

    signals.ejxCubeSettingsChanged.add(() => {
        if (player.setContentPerCubeFace) {
            const config = {
				front: editor.config.getKey( 'project/type/EJXCube/faceFront' ),
				right: editor.config.getKey( 'project/type/EJXCube/faceRight' ),
				back: editor.config.getKey( 'project/type/EJXCube/faceBack' ),
				left: editor.config.getKey( 'project/type/EJXCube/faceLeft' ),
				top: editor.config.getKey( 'project/type/EJXCube/faceTop' ),
				bottom: editor.config.getKey( 'project/type/EJXCube/faceBottom' ),
            }
            player.setContentPerCubeFace(config)
        }
        if (player.setCubeConfig) {
            const config = {
                borderOn: editor.config.getKey('project/type/EJXCube/borderOn'),
                borderWidth: editor.config.getKey('project/type/EJXCube/borderWidth'),
                borderFeather: editor.config.getKey('project/type/EJXCube/borderFeather'),
                borderColor: editor.config.getKey('project/type/EJXCube/borderColor'),
                borderAlpha: editor.config.getKey('project/type/EJXCube/borderAlpha'),
            }
            player.setCubeConfig(config)
        }
    })

    // Thumbnail generation
    signals.requestPlayerThumbnail.add(async (payload) => {
        if (started) {
			const originalSize = new THREE.Vector2();
			player.renderer.getSize(originalSize);
			const canvas = player.renderer.domElement;

			const controls = player.orbitControls ? player.orbitControls : player.controls;

			const oldCameraConfig = {
				position: player.camera.position.clone(),
				target: controls.target,
				fov: player.camera.fov,
				zoom: player.camera.zoom
			};

			let result = { success: false, shots: [] }

			for(var i = 0; i < payload.length; i++) {
				const shot = payload[i];
				player.resize(shot.width, shot.height, window.devicePixelRatio);
				player.camera.aspect = shot.width / shot.height;
				player.camera.position.copy(shot.position);
				controls.target = shot.target;
				player.camera.fov = shot.fov;
				player.camera.zoom = shot.zoom;
				player.camera.updateProjectionMatrix();
	
				const minDim = Math.min(canvas.width, canvas.height);
				const newWidth = (canvas.width / minDim) * shot.width;
				const newHeight = (canvas.height / minDim) * shot.height;

				const screenshot = await new Promise((res) => {
					window.requestAnimationFrame(() => {
						const resized = makeResizedCanvas(canvas, newWidth, newHeight);
						const halfWidth = resized.width / 2;
						const halfHeight = resized.height / 2;
	
						const x = halfWidth - shot.width / 2;
						const y = halfHeight - shot.height / 2;
	
						const cropped = makeCroppedCanvas(resized, x, y, shot.width, shot.height);
						res(cropped);
					})
				});
	
				const file = await canvasToFile(screenshot, 'image/jpeg', 'thumbnail.jpg');
	
				result.shots.push(file);
			}
	
			player.resize(originalSize.x, originalSize.y);
			player.camera.aspect = originalSize.x / originalSize.y;
			player.camera.position.copy(oldCameraConfig.position);
			controls.target = oldCameraConfig.target;
			player.camera.fov = oldCameraConfig.fov;
			player.camera.zoom = oldCameraConfig.zoom;
			player.camera.updateProjectionMatrix();

			result.success = true;
			signals.respondPlayerThumbnail.dispatch( result );
        }
    });

	signals.postprocessingSettingsChanged.add(() => {
        const postprocessingConfig = structuredClone(config.getKey('project/renderer/postprocessing'));
        player.setPostProcessingConfig(postprocessingConfig);
	});

	return container;
}

export { Player };
