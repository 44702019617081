import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { UIPanel, UIRow, UIText } from './libs/ui.js';

import { createEJXButton } from './utils.EJX.js';

function DialogNew( editor ) {

	const config = editor.config;
	const signals = editor.signals;

	const container = new UIPanel();

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		container.dom.appendChild( newProjectConfirmScreen );

	} );

	const newProjectConfirmScreen = document.createElement( 'div' );
	newProjectConfirmScreen.style.textAlign = 'center';
	const confirmMessage = new UIText( 'Any unsaved data will be lost. Are you sure?' );
	confirmMessage.dom.style.margin = '20px auto';
	confirmMessage.dom.style.color = '#C0FB00';
	newProjectConfirmScreen.appendChild( confirmMessage.dom );
	const buttonRow = new UIRow();
	buttonRow.dom.style.display = 'flex';
	buttonRow.dom.style.justifyContent = 'space-between';
	buttonRow.dom.appendChild( createEJXButton( 'Yes', () => {

		signals.requestNewProject.dispatch(config.getKey('project/newType'));
		signals.closeDialog.dispatch();
		
	}, 'primary' ) );
	buttonRow.dom.appendChild( createEJXButton( 'No', () => signals.closeDialog.dispatch(), 'secondary' ) );
	newProjectConfirmScreen.appendChild( buttonRow.dom );

	container.dom.appendChild( newProjectConfirmScreen );

	return container;

}

export { DialogNew };
