/**
* Cursed function that uses a throw to return the object in a scene graph that matches a predicate.
* Basically like [].find() but for a scene graph. 
*
* @param {THREE.Object3D} object  
* @param {(obj: THREE.Object3D) => boolean} predicate 
* @returns {THREE.Object3D | undefined} First traversed object that matches the predicate
*/
export function traverseFind(object, predicate) {
    try {
        object.traverse((obj) => {
            if (predicate(obj)) {
                throw obj;
            }
        })
    } catch(obj) {
        return obj
    }
}
