/**
 * This module manages versioning of app.json files and provides a function to ensure that
 * an app json is up to date and valid.
 *
 * When you call `VersioningUtils.migrateToLatest` it will read the version number, compare it with
 * the latest version and apply a set of changes to migrate it to the latest version.
 */

import { pushNotification } from "../EJXNotification";
import { Migrations } from "./migrations";

// This is what gets exported in the `version.editor` of the ejx.json file, `ejx.version.editor` of the `app.json` file.
// This will need to be incremented whenever there's breaking changes to the app.json file.
export const EJX_CURRENT_SCHEMA_VERSION = 2;

export const VersioningUtils = {
    /**
     * Gets the schema version of the app.json
     * @param {} appJson 
     */
    getVersion(appJson) {
        if (appJson && appJson.ejx) {
            const {version} = appJson.ejx;
            if (!version) return 0;

            const editorVersion = version.editor;
            if (!editorVersion) return 0;

            return editorVersion;
        } else {
            throw new Error('app.json is not valid.')
        }
    },
    /**
     * Checks if an app.json file is the latest version
     * @param {} appJson 
     * @returns {boolean} 
     */
    isLatest(appJson) {
        return this.getVersion(appJson) == EJX_CURRENT_SCHEMA_VERSION;
    },
    /**
     * Migrates an app.json file to the latest schema version.
     * @param {object} appJson - App JSON file
     * @throws {Error} when migration failed.
     */
    migrateToLatest(appJson) {
        if (this.isLatest(appJson)) {
            return appJson;
        }

        let data = appJson;
        const initialVersion = this.getVersion(appJson);
        for (let i = initialVersion; i < EJX_CURRENT_SCHEMA_VERSION; i++) {
            try {
                data = Migrations[i](data);
            } catch (error) {
                const wrapped = new Error(`MigrationError: Error while migrating from ${i} to ${i+1}. [${initialVersion} -> ${EJX_CURRENT_SCHEMA_VERSION}]`);
                wrapped.cause = error;
                throw error;
            }
        }

        return data;
    }
}
