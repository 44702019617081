const EJX_API_URL = import.meta.env.PUBLIC_EJX_API_URL;
const EJX_CDN_URL = import.meta.env.PUBLIC_EJX_CDN_URL;
const EJX_COGNITO_CLIENT_ID = import.meta.env.PUBLIC_EJX_COGNITO_CLIENT_ID;
const EJX_COGNITO_USERPOOL_ID = import.meta.env.PUBLIC_EJX_COGNITO_USERPOOL_ID;
const EJX_AWS_REGION = import.meta.env.PUBLIC_EJX_AWS_REGION;
const EJX_SIGN_UP_URL = import.meta.env.PUBLIC_EJX_SIGN_UP_URL;


const DIALOG_SCREEN_STATES = {
	LOGIN: 'login',
    SIGN_UP: 'sign_up',
    SIGN_UP_CONFIRM: 'sign_up_confirm',
	NEW: 'new',
	PROJECT_TYPE: 'project_type',
	THUMBNAIL: 'thumbnail',
	OPEN: 'open',
    NEEDS_SAVE: 'needs_save', // Dialog screen communicating that this action requires saving.
	SAVING: 'saving',
	LOADING: 'loading',
	SAVE_SUCCESS: 'save_success',
	PLAY: 'play',
    ABOUT: 'about',
};

export {
	EJX_API_URL,
	EJX_CDN_URL,
	EJX_COGNITO_CLIENT_ID,
	EJX_COGNITO_USERPOOL_ID,
	EJX_AWS_REGION,
	EJX_SIGN_UP_URL,
	DIALOG_SCREEN_STATES
};
