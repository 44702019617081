import { pushNotification } from "./EJXNotification";
import { UIDiv } from "./libs/ui";
import { createEJXButton } from "./utils.EJX";


export function SidebarEJXPublish( editor ) {
    // State
    let publishedProject;

    async function handleUnpublishLatest() {
        const workspaceId = editor.config.getKey('user/workspace');
        const projectId = editor.config.getKey('project/id');
        if (!workspaceId | !projectId) {
            return pushNotification(editor, {
                type: 'error',
                title: 'The project is not loaded correctly',
                description: 'Please refresh and reload the project.',
            })
        }

        console.log(publishedProject);
        if (!publishedProject) {
            return pushNotification(editor, {
                type: 'error',
                title: 'No published project',
            })
        }

        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Unpublishing project...')
        await editor.ejxAPI.deletePublished(
            workspaceId,
            projectId,
            publishedProject.versionId,
        );
    }

    async function handlePublish() {
        const workspaceId = editor.config.getKey('user/workspace');
        const projectId = editor.config.getKey('project/id');
        if (!workspaceId | !projectId) {
            return pushNotification(editor, {
                type: 'error',
                title: 'The project is not loaded correctly',
                description: 'Please refresh and reload the project.',
            })
        }

        const latest = await editor.ejxAPI.getLatestVersion(workspaceId, projectId);
        if (!latest) {
            return pushNotification(editor, {
                type: 'error',
                title: 'Save project before publishing',
            })
        }

        editor.signals.loadingStarted.dispatch();
        editor.signals.setLoadingStatus.dispatch('Publishing project...')
        await editor.ejxAPI.putPublished(
            workspaceId,
            projectId,
            latest.versionId,
        );
    }

    // Dom
    const container = new UIDiv();
    container.addClass('publish-container')
    container.addClass('capability--publish')

    const ejxPublishButton = createEJXButton('Publish', () => {
        let description = '';
        if (publishedProject) {
            const creationDate = new Date(Number.parseInt(publishedProject.creationDate));
            description +=  `This will replace the previously published version (from ${creationDate.toLocaleString()}).`;
        } else {
            description += 'This artwork will be permanently accessible via a url until it is unpublished or overwritten.'
        }

        editor.signals.showOverlay.dispatch({
            title: 'Publish latest version of this artwork?',
            description,
            buttons: [
                { text: 'Publish', type: 'primary', action: async () => {
                    if (publishedProject) {
                        await handleUnpublishLatest()
                    }
                    await handlePublish();
                    editor.signals.loadingFinished.dispatch();
                    editor.signals.hideOverlay.dispatch();
                    refresh();
                } },
            ]
        })
    }, 'primary')
    ejxPublishButton.style.flex = '1 0 70%';
    container.dom.append(ejxPublishButton);

    const ejxViewPublishedButton = createEJXButton('🔗', () => {
        console.log(publishedProject);
        const basePath = import.meta.env.PUBLIC_STAGE === 'prod' 
            ? 'https://eyejack.xyz/' 
            : 'https://dev.eyejack.xyz/';
        window.open(basePath + publishedProject.prefix, '_blank');
    });
    ejxViewPublishedButton.style.display = 'none';
    ejxViewPublishedButton.style.flex = '0 1 auto';
    container.dom.append(ejxViewPublishedButton);

    const ejxUnpublishButton = createEJXButton('❌', () => {
        const creationDate = new Date(Number.parseInt(publishedProject.creationDate));
        editor.signals.showOverlay.dispatch({
            title: 'Remove published artwork?',
            description: `Version was published on ${creationDate.toLocaleString()}.`,
            buttons: [
                { text: 'Unpublish', type: 'primary', action: async () => {
                    await handleUnpublishLatest()
                    editor.signals.loadingFinished.dispatch();
                    editor.signals.hideOverlay.dispatch();
                    refresh();
                } },
            ]
        })
    });
    ejxUnpublishButton.style.display = 'none';
    ejxUnpublishButton.style.flex = '0 1 auto';
    container.dom.append(ejxUnpublishButton);


    // Renderer
    const render = () => {
        if (publishedProject) {
            ejxViewPublishedButton.style.display = "flex";
            ejxUnpublishButton.style.display = "flex";
        } else {
            ejxViewPublishedButton.style.display = "none";
            ejxUnpublishButton.style.display = "none";
        }
    }

    const refresh = async () => {
        publishedProject = undefined;
        render();

        const workspaceId = editor.config.getKey('user/workspace');
        const projectId = editor.config.getKey('project/id');

        if (workspaceId || projectId) {
            publishedProject = await editor.ejxAPI.getPublished(workspaceId, projectId);
            render();
        }
    }

    editor.signals.userCapabilitiesChanged.add(async (capabilities) => {
        if (capabilities.some(cap => cap.capability === 'publish')) {
            refresh();
        }
    });
    editor.signals.projectLoaded.add(() => {
        const capabilities = editor.config.getKey('user/capabilities');
        if (capabilities.some(cap => cap.capability === 'publish')) {
            refresh();
        }
    })

    return container;
}
