import { UIPanel, UIRow, UIText } from './libs/ui.js';

import { createEJXButton, createForm, createInput } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES, EJX_COGNITO_CLIENT_ID, EJX_COGNITO_USERPOOL_ID, EJX_SIGN_UP_URL } from './Config.EJX.js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import AuthUtils, { AuthErrors } from './utils/auth.js';
import { captureException } from '@sentry/astro';

function DialogLogin( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	var cognitoUser, newPasswordRequiredErr;

	const onLogin = async ( e ) => {

		e.preventDefault();

		signals.setLoadingStatus.dispatch( 'Logging in...' );
		signals.loadingStarted.dispatch();

        const usernameInput = document.getElementById( 'username' );
        const passwordInput = document.getElementById( 'password' );
        const username = usernameInput.value;
        const password = passwordInput.value;

        /** @type {AmazonCognitoIdentity.CognitoUserSession} */
        let session;
        try {
            session = await AuthUtils.login(username, password);
            ejxApi.refreshUserCapabilities();
        } catch (err) {
            if (err instanceof AuthErrors.NewPasswordRequiredError) {
                signals.loadingFinished.dispatch();

                // the api doesn't accept this field back
                delete err.userAttributes.email_verified;

                // store userAttributes on global variable
                newPasswordRequiredErr = err;

                usernameInput.value = '';
                passwordInput.value = '';

                container.dom.removeChild( loginScreen );
                container.dom.appendChild( newPasswordScreen );
            } else if (err instanceof AuthErrors.UserNotConfirmedError) {
                signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.SIGN_UP_CONFIRM, {
                    message: 'Enter the confirmation code that we sent to your email below.',
                    username: username,
                    password: password,
                })
            } else if (err instanceof AuthErrors.IncorrectDetailsError || err instanceof AuthErrors.UserNotFoundError) {
                errorMessage.setValue( 'The username or password you entered was incorrect.' );
            } else {
                captureException(err);

                errorMessage.setValue( err.message || JSON.stringify( err ) );

                usernameInput.style.border = '1px solid #FF0000';
                passwordInput.style.border = '1px solid #FF0000';
                usernameInput.value = '';
                passwordInput.value = '';
                signals.loadingFinished.dispatch();
                signals.closeDialog.dispatch();
            }
            return;
        } finally {
            signals.loadingFinished.dispatch();
        } 

        signals.closeDialog.dispatch();

	};

	const onUpdatePassword = async ( e ) => {

        const passwordInvalidMessage = AuthUtils.validatePassword(newPasswordInput.value);

		if ( newPasswordInput.value !== reEnterNewPasswordInput.value ) {

			newPasswordErrorMessage.setValue( 'Passwords do not match.' );
			newPasswordInput.style.border = '1px solid #FF0000';
			reEnterNewPasswordInput.style.border = '1px solid #FF0000';
			return;

		} else if (passwordInvalidMessage) {

			newPasswordErrorMessage.setValue( passwordInvalidMessage );
			newPasswordInput.style.border = '1px solid #FF0000';
			reEnterNewPasswordInput.style.border = '1px solid #FF0000';

        } else {

            console.log(newPasswordRequiredErr);
            if (!newPasswordRequiredErr) {
                throw new Error('Trying to reset password but no newPasswordRequiredErr was set.')
            }
            try {
                await AuthUtils.completeNewPassword(newPasswordRequiredErr, newPasswordInput.value);
            } catch(reason) {
                if (reason instanceof AuthErrors.InvalidPasswordError) {
                    newPasswordErrorMessage.setValue( reason.message );
                    newPasswordInput.style.border = '1px solid #FF0000';
                    reEnterNewPasswordInput.style.border = '1px solid #FF0000';
                    return;
                } else {
                    throw reason;
                }
            }

            newPasswordInput.value = '';
            reEnterNewPasswordInput.value = '';

            container.dom.removeChild( newPasswordScreen );
            container.dom.appendChild( loginSuccessScreen );

            signals.loginSuccess.dispatch();

		}

	};

	const validateInputs = ( e ) => {

		if ( usernameInput.value.length > 0 && passwordInput.value.length > 0 ) {

			loginButton.disabled = false;

		} else {

			loginButton.disabled = true;

		}

	};

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		usernameInput.style.border = 'none';
		passwordInput.style.border = 'none';
		loginButton.disabled = true;
		errorMessage.setValue( '' );
		container.dom.appendChild( loginScreen );

	} );


	const loginScreen = document.createElement( 'div' );
	const loginText = new UIText( 'Login to your EJX account to publish and view your projects in AR.' );
	loginText.dom.style.textAlign = 'center';
	loginText.dom.style.width = '230px';
	loginText.dom.style.margin = '20px auto';
	loginText.dom.style.color = '#D9D9D9';
	loginText.setDisplay( 'block' );
	loginScreen.appendChild( loginText.dom );

	const usernameInput = createInput( 'text', 'username', 'username' );
	usernameInput.addEventListener( 'input', validateInputs );

	const passwordInput = createInput( 'password', 'password', 'password' );
	passwordInput.addEventListener( 'input', validateInputs );
	passwordInput.style.marginBottom = '0';

    // Required to validate auto-filled inputs
    editor.signals.launchDialog.add((dialog) => {
        if (dialog === DIALOG_SCREEN_STATES.LOGIN) {
            setTimeout(validateInputs, 10);
        }
    })

	const loginButton = createEJXButton( 'Login', onLogin, 'primary' );
	loginButton.type = "submit";
	loginButton.disabled = true;
	const errorMessage = new UIText( '' );
    errorMessage.setStyle( 'margin', [ '5px 0' ] )
	errorMessage.setStyle( 'color', [ '#FF8888' ] );
	errorMessage.setStyle( 'font-size', [ '16px' ] );
	loginScreen.appendChild( createForm( [ usernameInput, passwordInput, errorMessage.dom, loginButton ] ) );
	const signUpMessage = new UIText( 'Not signed up yet?' );

	const signUpLink = document.createElement( 'a' );
	signUpLink.href = '#';
    signUpLink.addEventListener('click', () => {
        editor.signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.SIGN_UP);
    });
	signUpLink.textContent = 'Register an account';
	signUpLink.style.marginLeft = '6px';
	const signUpText = new UIRow();
	signUpText.dom.style.textAlign = 'center';
	signUpText.dom.style.width = '300px';
	signUpText.dom.style.margin = '0px auto';
	signUpText.dom.style.color = '#D9D9D9';
	signUpText.setDisplay( 'block' );
	signUpText.dom.appendChild( signUpMessage.dom );
	signUpText.dom.appendChild( signUpLink );
	loginScreen.appendChild( signUpText.dom );

	const loginSuccessScreen = document.createElement( 'div' );
	loginSuccessScreen.style.textAlign = 'center';
	const successMessage = new UIText( 'Login Successful!' );
	successMessage.dom.style.margin = '20px auto';
	successMessage.dom.style.color = '#C0FB00';
	loginSuccessScreen.appendChild( successMessage.dom );

	const newPasswordScreen = document.createElement( 'div' );
	const newPasswordText = new UIText( 'Please enter a new password for your account.' );
	newPasswordText.dom.style.textAlign = 'center';
	newPasswordText.dom.style.width = '230px';
	newPasswordText.dom.style.margin = '20px auto';
	newPasswordText.dom.style.color = '#C0FB00';
	newPasswordText.setDisplay( 'block' );
	newPasswordScreen.appendChild( newPasswordText.dom );
	const newPasswordInput = createInput( 'password', 'newPassword', 'New Password', 'newPassword' );
	newPasswordInput.id = 'newPassword';
	newPasswordInput.style.margin = '0 auto';
	newPasswordInput.style.width = '230px';
	newPasswordInput.style.marginBottom = '10px';
	newPasswordScreen.appendChild( newPasswordInput );
	const reEnterNewPasswordInput = createInput( 'password', 'reEnterNewPassword', 'Re-enter New Password', 'reEnterNewPassword' );
	reEnterNewPasswordInput.id = 'reEnterNewPassword';
	reEnterNewPasswordInput.style.margin = '0 auto';
	reEnterNewPasswordInput.style.width = '230px';
	reEnterNewPasswordInput.style.marginBottom = '10px';
	newPasswordScreen.appendChild( reEnterNewPasswordInput );
	const newPasswordErrorMessage = new UIText( '' );
	newPasswordErrorMessage.setStyle( 'color', [ '#FF0000' ] );
	newPasswordErrorMessage.setStyle( 'font-size', [ '14px' ] );
	newPasswordErrorMessage.dom.style.margin = '0 auto';
	newPasswordErrorMessage.dom.style.width = '230px';
	newPasswordErrorMessage.dom.style.marginBottom = '10px';
	newPasswordErrorMessage.dom.style.display = 'block';
	newPasswordScreen.appendChild( newPasswordErrorMessage.dom );
	const newPasswordButton = createEJXButton( 'Update password', onUpdatePassword, 'primary' );
	newPasswordButton.style.margin = '0 auto';
	newPasswordButton.style.width = '230px';
	newPasswordButton.style.marginBottom = '10px';
	newPasswordScreen.appendChild( newPasswordButton );

	container.dom.appendChild( loginScreen );

	return container;

}

export { DialogLogin };
