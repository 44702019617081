import {
	UICheckbox,
	UIDiv,
	UINumber,
	UIPanel,
	UIRow,
	UIText,
} from './libs/ui.js';

class EffectHelper {

	constructor( config ) {

		this.config = config;
        const state = config.getKey( 'project/renderer/postprocessing' ) 
		this.state = state ? state : [];

	}

	hasEffect( type ) {

		return this.state.some( ( effect ) => effect.type === type );

	}
	getEffect( type ) {

		return this.state.find( ( effect ) => effect.type === type );

	}
	removeEffect( type ) {

		this.state = this.state.filter( ( effect ) => effect.type !== type );
		return this;

	}
	addEffect( effect ) {

		this.state.push( effect );
		return this;

	}

	getEffectParam( type, param ) {

		const effect = this.getEffect( type );
		if ( effect ) {

			return effect[ param ];

		}

	}
	setEffectParam( type, param, value ) {

		const effect = this.getEffect( type );
		if ( effect ) {

			return effect[ param ] = value;

		}

		return this;

	}

	save() {

		this.config.setKey( 'project/renderer/postprocessing', this.state );

	}

}

function SidebarProjectPostprocessing( editor ) {

	const scene = editor.scene;
	const config = editor.config;
	const strings = editor.strings;
	const signals = editor.signals;

	const container = new UIPanel();

	const headerRow = new UIRow();
	headerRow.add(
		new UIText(
			strings.getKey( 'sidebar/project/postprocessing/title' ).toUpperCase(),
		),
	);
	container.add( headerRow );

	/**
   * Bloom
   */
	const bloomRow = new UIDiv();
	container.add( bloomRow );
	bloomRow.add(
		new UIText( strings.getKey( 'sidebar/project/postprocessing/bloom' ) ).setWidth(
			'110px',
		),
	);
	const bloomEnabled = new UICheckbox().onChange( () => {

		const helper = new EffectHelper( config );
		const hasEffect = helper.hasEffect( 'bloom' );
		if ( hasEffect ) {

			helper.removeEffect( 'bloom' );

		} else {

			helper.addEffect( {
				type: 'bloom',
                exposure: 1,
				threshold: bloomThresholdInput.getValue(),
				strength: bloomStrengthInput.getValue(),
				radius: bloomRadiusInput.getValue(),
			} );

		}

		helper.save();
		bloomEnabled.setValue( helper.hasEffect( 'bloom' ) );
		signals.postprocessingSettingsChanged.dispatch();

	} );
	bloomRow.add( bloomEnabled );

	const bloomOptsContainer = new UIDiv();

	// Bloom threshold
	const bloomThresholdDefault = 0.05;
	const bloomThresholdInput = new UINumber( bloomThresholdDefault ).onChange( () => {

		const helper = new EffectHelper( config );
		helper.setEffectParam(
			'bloom',
			'threshold',
			bloomThresholdInput.getValue(),
		);
		helper.save();
		signals.postprocessingSettingsChanged.dispatch();

	} );
	bloomThresholdInput.min = 0;
	bloomThresholdInput.max = 1;
	signals.editorCleared.add(() => { bloomThresholdInput.setValue( bloomThresholdDefault ) });
	const bloomThresholdRow = new UIRow().add(
		new UIText(
			strings.getKey( 'sidebar/project/postprocessing/bloom/bloomThreshold' ),
		).setWidth( '90px' ),
	).add( bloomThresholdInput );

	// Bloom strength
	const bloomStrengthDefault = 1;
	const bloomStrengthInput = new UINumber( bloomStrengthDefault ).onChange( () => {

		const helper = new EffectHelper( config );
		helper.setEffectParam(
			'bloom',
			'strength',
			bloomStrengthInput.getValue(),
		);
		helper.save();
		signals.postprocessingSettingsChanged.dispatch();

	} );
	bloomStrengthInput.min = 0;
	bloomStrengthInput.max = 1;
	signals.editorCleared.add(() => { bloomStrengthInput.setValue( bloomStrengthDefault ) });
	const bloomStrengthRow = new UIRow().add(
		new UIText(
			strings.getKey( 'sidebar/project/postprocessing/bloom/bloomStrength' ),
		).setWidth( '90px' ),
	).add( bloomStrengthInput );


	// Bloom radius
	const bloomRadiusDefault = 0.2;
	const bloomRadiusInput = new UINumber( bloomRadiusDefault ).onChange( () => {

		const helper = new EffectHelper( config );
		helper.setEffectParam( 'bloom', 'radius', bloomRadiusInput.getValue() );
		helper.save();
		new EffectHelper( config ).save();
		signals.postprocessingSettingsChanged.dispatch();

	} );
	bloomRadiusInput.min = 0;
	bloomRadiusInput.max = 0.5;
	signals.editorCleared.add(() => { bloomRadiusInput.setValue( bloomRadiusDefault ) });
	const bloomRadiusRow = new UIRow().add(
		new UIText(
			strings.getKey( 'sidebar/project/postprocessing/bloom/bloomRadius' ),
		).setWidth( '90px' ),
	).add( bloomRadiusInput );

	bloomOptsContainer.add( bloomThresholdRow, bloomStrengthRow, bloomRadiusRow );
	bloomRow.add( bloomOptsContainer );

	const handleProjectUpdate = () => {
		
		const helper = new EffectHelper( config );
		const hasBloom = helper.hasEffect( 'bloom' );
		bloomEnabled.setValue( hasBloom );
		if ( hasBloom ) {

			bloomThresholdInput.setValue( helper.getEffectParam( 'bloom', 'bloomThreshold' ) );
			bloomStrengthInput.setValue( helper.getEffectParam( 'bloom', 'bloomStrength' ) );
			bloomRadiusInput.setValue( helper.getEffectParam( 'bloom', 'bloomRadius' ) );

		}

		updateUI();

	};

	const updateUI = () => {

		const helper = new EffectHelper( config );
		bloomRadiusInput.setValue( helper.getEffectParam( 'bloom', 'bloomRadius' ) );
		if ( helper.hasEffect( 'bloom' ) ) {

			bloomOptsContainer.removeClass( 'hidden' );

		} else {

			bloomOptsContainer.addClass( 'hidden' );

		}

	};

	handleProjectUpdate();

	/* Signals */
	editor.signals.postprocessingSettingsChanged.add( handleProjectUpdate )
	editor.signals.editorCleared.add( handleProjectUpdate );
	editor.signals.startPlayer.add( handleProjectUpdate );
	editor.signals.stopPlayer.add( handleProjectUpdate );

	return container;

}

export { SidebarProjectPostprocessing };
