import { UIPanel, UIRow, UIText } from './libs/ui.js';

import { createEJXButton, createForm, createInput } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES, EJX_SIGN_UP_URL } from './Config.EJX.js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import AuthUtils, { AuthErrors } from './utils/auth.js';
import { pushNotification } from './EJXNotification.js';
import { captureException } from '@sentry/astro';

function DialogSignupConfirm( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

    let username = undefined;
    let email = undefined;
    let password = undefined;

	const onConfirm = async ( e ) => {

		e.preventDefault();

		signals.setLoadingStatus.dispatch( 'Confirming account...' );
		signals.loadingStarted.dispatch();

        const confirmationCode = codeInput.value;
        try {
            await AuthUtils.verify(username, confirmationCode);
        } catch (error) {
            signals.loadingFinished.dispatch();

            if (error instanceof AuthErrors.InvalidInputError) {
                errorMessage.setValue("The code was incorrect.  Make sure you're entering the latest code we sent you.")
                return;
            }
            throw error;
        }

        // Auto confirm if necessary data is here.
        const confirmUsername = username || email;
        if (confirmUsername && password) {
            try {
                await AuthUtils.login(confirmUsername, password);
            } catch(err) {
                signals.loadingFinished.dispatch();
                captureException(err);
                pushNotification(editor, {
                    type: 'success',
                    title: 'Account confirmed!',
                    description: 'However, we could not log you in automatically.  Please enter your login details again.',
                    timeout: 20_000,
                })
                editor.signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.LOGIN);
                return;
            }
        }


        signals.loadingFinished.dispatch();
        signals.closeDialog.dispatch();
	};

	const validateInputs = ( e ) => {
        const confirmationCode = codeInput.value;

        const error = AuthUtils.validateVerify(username, confirmationCode);
        if (error) {
            confirmButton.disabled = true;
            errorMessage.dom.innerText = error;
        } else {
            confirmButton.disabled = false;
            errorMessage.dom.innerText = '';
        }

	};

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		confirmButton.disabled = true;
		errorMessage.setValue( '' );
		container.dom.appendChild( confirmScreen );

	} );


	const confirmScreen = document.createElement( 'div' );
	const confirmText = new UIText( "" );
	confirmText.dom.style.textAlign = 'center';
	confirmText.dom.style.width = '400px';
	confirmText.dom.style.margin = '20px auto';
	confirmText.dom.style.color = '#D9D9D9';
	confirmText.setDisplay( 'block' );
	confirmScreen.appendChild( confirmText.dom );

	const codeInput = createInput( 'number', 'confirmation-code', 'Code' );
    codeInput.setAttribute('pattern', "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/")
	codeInput.addEventListener( 'input', validateInputs );
	codeInput.style.marginBottom = '0';

	const confirmButton = createEJXButton( 'Confirm', onConfirm, 'primary' );
	confirmButton.type = "submit";
	confirmButton.disabled = true;
	const errorMessage = new UIText( '' );
    errorMessage.setStyle( 'margin', [ '5px 0' ] )
	errorMessage.setStyle( 'color', [ '#FF8888' ] );
	errorMessage.setStyle( 'font-size', [ '16px' ] );
	confirmScreen.appendChild( createForm( [ codeInput, errorMessage.dom, confirmButton ] ) );

	const resendMessage = new UIText( "Didn't receive a code?" );
	const resendLink = document.createElement( 'a' );
	resendLink.href = '#';
    resendLink.addEventListener('click', async () => {
		signals.setLoadingStatus.dispatch( 'Sending confirmation code...' );
		signals.loadingStarted.dispatch();
        await AuthUtils.resendConfirmation(username);
        signals.loadingFinished.dispatch();
    });
	resendLink.textContent = 'Send another confirmation code.';
	resendLink.style.marginLeft = '6px';
	const resendText = new UIRow();
	resendText.dom.style.textAlign = 'center';
	resendText.dom.style.width = '300px';
	resendText.dom.style.margin = '0px auto';
	resendText.dom.style.color = '#D9D9D9';
	resendText.setDisplay( 'block' );
	resendText.dom.appendChild( resendMessage.dom );
	resendText.dom.appendChild( resendLink );
	confirmScreen.appendChild( resendText.dom );

	container.dom.appendChild( confirmScreen );

    signals.launchDialog.add((screen, options) => {
        if (screen !== DIALOG_SCREEN_STATES.SIGN_UP_CONFIRM) return;
        if (!options || !options.username) {
            throw new Error(`Dialog.SignupConfirm: Must pass in properties to the signal:
            signals.dispatch(DIALOG_SCREEN_STATES.SIGN_UP_CONFIRM, { username: '...', password?: '...', email:? '...' })
            `);
        }

        if (options.message) {
            confirmText.setValue(options.message);
        } else if (options.email) {
            confirmText.setValue(`We sent a confirmation code to ${options.email}.  Enter the code below to verify your account.`);
        }

        username = options.username;
        email = options.email;
        password = options.password;
    })

	return container;

}

export { DialogSignupConfirm };
