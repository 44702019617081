import SpinnerImg from '../images/spinner.png';
import { UIPanel, UIDiv } from './libs/ui.js';

export function OverlayLoading(editor) {

	const signals = editor.signals;
	const container = new UIPanel();

	signals.loadingStarted.add( () => {

		loadingScreen.style.display = 'flex';

	} );

	signals.setLoadingStatus.add( (text) => {

		if( !loadingScreen.contains( loadingStatus ) ) {
			loadingScreen.appendChild( loadingStatus );
		}

		loadingStatus.innerText = text;

	} );

	signals.loadingFinished.add( () => {

		if( loadingScreen.contains( loadingStatus ) ) {
			loadingStatus.innerText = '';
			loadingScreen.removeChild( loadingStatus );
		}

		loadingScreen.style.display = 'none';

	} );

	signals.ejxSaveFinished.add( () => {

		loadingScreen.style.display = 'none';

	} );

	const loadingScreen = document.createElement('dialog');
	loadingScreen.id = 'loadingScreen';

	const spinner = document.createElement( 'img' );
	spinner.src = SpinnerImg.src;
	spinner.className = 'animate-spin';

	loadingScreen.appendChild( spinner );

	const loadingStatus = document.createElement( 'p' );
	loadingStatus.id = 'loadingStatus';


	container.dom.append( loadingScreen );
    return container;
}
