import { UIPanel, UIRow, UIText } from './libs/ui.js';

import { createEJXButton, createForm, createInput } from './utils.EJX.js';
import { DIALOG_SCREEN_STATES, EJX_COGNITO_CLIENT_ID, EJX_COGNITO_USERPOOL_ID, EJX_SIGN_UP_URL } from './Config.EJX.js';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import AuthUtils, { AuthErrors } from './utils/auth.js';

function DialogSignup( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	var cognitoUser, sessionUserAttributes;

	const onSignup = async ( e ) => {

		e.preventDefault();

		signals.setLoadingStatus.dispatch( 'Creating acount...' );
		signals.loadingStarted.dispatch();

        const username = usernameInput.value;
        const password = passwordInput.value;
        const email = emailInput.value;

        if (typeof email !== 'string' || email.length === 0) {
            throw new Error('Email is empty.')
        }

        try {
            await AuthUtils.signup(email, username, password);
        } catch (err) {
            if (err instanceof AuthErrors.UsernameExistsError) {
                return errorMessage.setValue('This username is already in use.  Did you mean to login?');
            } else {
                throw err;
            }
        } finally {
            signals.loadingFinished.dispatch();
        }

        signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.SIGN_UP_CONFIRM, {
            username,
            password,
            email,
        })
	};

	const validateInputs = ( e ) => {

        const email = emailInput.value;
        const username = usernameInput.value;
        const password = passwordInput.value;

        const error = AuthUtils.validateSignup(email, username, password);
        if (error) {
            loginButton.disabled = true;
            errorMessage.dom.innerText = error;
        } else {
            loginButton.disabled = false;
            errorMessage.dom.innerText = '';
        }
	};

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		usernameInput.style.border = 'none';
		passwordInput.style.border = 'none';
		loginButton.disabled = true;
		errorMessage.setValue( '' );
		container.dom.appendChild( loginScreen );

	} );


	const loginScreen = document.createElement( 'div' );
	const loginText = new UIText( 'Create a new EJX account.' );
	loginText.dom.style.textAlign = 'center';
	loginText.dom.style.width = '230px';
	loginText.dom.style.margin = '20px auto';
	loginText.dom.style.color = '#D9D9D9';
	loginText.setDisplay( 'block' );
	loginScreen.appendChild( loginText.dom );

	const emailInput = createInput( 'email', 'email', 'email' );
    emailInput.style.width = '250px'
    emailInput.setAttribute('pattern', "[^@\s]+@[^@\s]+\.[^@\s]+")
	emailInput.addEventListener( 'input', validateInputs );
	emailInput.style.marginBottom = '0';

	const usernameInput = createInput( 'text', 'username', 'username' );
    usernameInput.style.width = '250px'
	usernameInput.setAttribute( 'minlength', 3 );
	usernameInput.setAttribute( 'maxlength', 32 );
	usernameInput.addEventListener( 'input', validateInputs );

	const passwordInput = createInput( 'password', 'password', 'password' );
    passwordInput.style.width = '250px'
	passwordInput.addEventListener( 'input', validateInputs );
    // Includes at least 1 digit and 1 capitalized char
    passwordInput.setAttribute('patern', '((\d)|[A-Z])')
    passwordInput.setAttribute('minlength', 8)
	passwordInput.style.marginBottom = '0';

	const loginButton = createEJXButton( 'Sign Up', onSignup, 'primary' );
	loginButton.type = "submit";
	loginButton.disabled = true;
	const errorMessage = new UIText( '' );
    errorMessage.setStyle( 'margin', [ '5px 0' ] )
	errorMessage.setStyle( 'color', [ '#FF8888' ] );
	errorMessage.setStyle( 'font-size', [ '16px' ] );
    const loginForm = createForm( [ emailInput, usernameInput, passwordInput, errorMessage.dom, loginButton ] );
    loginForm.style.width = '250px';
	loginScreen.appendChild(loginForm);

	const signUpMessage = new UIText( 'Already have an account?' );
	const signUpLink = document.createElement( 'a' );
	signUpLink.href = '#';
    signUpLink.addEventListener('click', () => {
        editor.signals.launchDialog.dispatch(DIALOG_SCREEN_STATES.LOGIN);
    });
	signUpLink.textContent = 'Sign in';
	signUpLink.style.marginLeft = '6px';
	const signUpText = new UIRow();
	signUpText.dom.style.textAlign = 'center';
	signUpText.dom.style.width = '300px';
	signUpText.dom.style.margin = '0px auto';
	signUpText.dom.style.color = '#D9D9D9';
	signUpText.setDisplay( 'block' );
	signUpText.dom.appendChild( signUpMessage.dom );
	signUpText.dom.appendChild( signUpLink );
	loginScreen.appendChild( signUpText.dom );


	container.dom.appendChild( loginScreen );

	return container;

}

export { DialogSignup };
