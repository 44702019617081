import { UITabbedPanel, UISpan, UIDiv } from './libs/ui.js';

import { SidebarScene } from './Sidebar.Scene.js';
import { SidebarProperties } from './Sidebar.Properties.js';
import { SidebarScript } from './Sidebar.Script.js';
import { SidebarAnimation } from './Sidebar.Animation.js';
import { SidebarProject } from './Sidebar.Project.js';
import { SidebarSettings } from './Sidebar.Settings.js';
import { SidebarEJX } from './Sidebar.EJX.js';
import { SidebarPreview } from './Sidebar.Preview.js';

function Sidebar( editor ) {
	const signals = editor.signals;
	const strings = editor.strings;

	const container = new UIDiv();
	container.setId( 'sidebar' );

	const tabPanel = new UITabbedPanel();
	tabPanel.dom.style.flexGrow = 1;
	tabPanel.dom.style.flexShrink = 1;
	tabPanel.dom.style.overflowY = "auto";
	container.add(tabPanel);

	const scene = new UISpan().add(
		new SidebarScene( editor ),
		new SidebarProperties( editor ),
		new SidebarAnimation( editor ),
		new SidebarScript( editor )
	);

	const project = new SidebarProject( editor );
	const settings = new SidebarSettings( editor );
	const preview = new SidebarPreview( editor );

	tabPanel.addTab( 'scene', strings.getKey( 'sidebar/scene' ), scene );
	tabPanel.addTab( 'project', strings.getKey( 'sidebar/project' ), project );
	tabPanel.addTab( 'settings', strings.getKey( 'sidebar/settings' ), settings );
	tabPanel.select( 'scene' );
	const editMode = () => {
		tabPanel.clearTabs();
		tabPanel.addTab( 'scene', strings.getKey( 'sidebar/scene' ), scene );
		tabPanel.addTab( 'project', strings.getKey( 'sidebar/project' ), project );
		tabPanel.addTab( 'settings', strings.getKey( 'sidebar/settings' ), settings );
		tabPanel.select( 'scene' );
	};

	const ejxSidebar = new SidebarEJX( editor );
	ejxSidebar.dom.style.flexShrink = 0;
	container.add( ejxSidebar );
	editMode();

	const previewMode = () => {
		tabPanel.clearTabs();
		tabPanel.addTab( 'preview', 'Preview', preview );
	};

	signals.startPlayer.add( previewMode );
	signals.stopPlayer.add( editMode );

	return container;

}

export { Sidebar };
