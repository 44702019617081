import { DIALOG_SCREEN_STATES } from './Config.EJX.js';
import { UIPanel, UIRow } from './libs/ui.js';

import { createEJXButton, createHeading } from './utils.EJX.js';

function DialogNeedsSave( editor ) {

	const strings = editor.strings;
	const config = editor.config;
	const signals = editor.signals;
	const ejxApi = editor.ejxAPI;

	const container = new UIPanel();

	const screen = document.createElement( 'div' );
	screen.appendChild( createHeading( 'Saving to EJX' ) );
	const text = document.createElement( 'p' );
	text.textContent = 'This action requires saving your latest changes.  Do you want to save the project?';
	screen.appendChild( text );

    const buttonRow = new UIRow();
    buttonRow.dom.style.display = 'flex';
    buttonRow.dom.style.justifyContent = 'space-between';
    buttonRow.dom.style.marginTop = '20px';
    screen.append(buttonRow.dom);

    buttonRow.dom.appendChild(createEJXButton('Save and Continue', () => {
		signals.launchDialog.dispatch( DIALOG_SCREEN_STATES.SAVING );
    }));
    buttonRow.dom.appendChild(createEJXButton('Cancel', () => {
        signals.closeDialog.dispatch();
    }, 'secondary'));
	container.dom.appendChild( screen );

	signals.closeDialog.add( () => {

		while ( container.dom.firstChild ) {

			container.dom.removeChild( container.dom.lastChild );

		}

		container.dom.appendChild( screen );


	} );

	return container;

}

export { DialogNeedsSave };
