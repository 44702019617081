/**
 * @module Image Utilities
 */

/**
 * Creates a new canvas that is a resized version of the initial canvas.
 * @param {HTMLCanvasElement} canvas
 * @param {number} width
 * @param {number} height
 */
export function makeResizedCanvas(canvas, width = 1024, height=1024) {
    const newCanvas = document.createElement('canvas');
    newCanvas.width = width;
    newCanvas.height = height;

    const ctx = newCanvas.getContext('2d');
    ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, newCanvas.width, newCanvas.height);

    return newCanvas;
}
/**
 * Creates a new canvas that is a cropped version of the initial canvas.
 * @param {HTMLCanvasElement} canvas
 * @param {number} x
 * @param {height} y
 * @param {number} width
 * @param {number} height
 */
export function makeCroppedCanvas(canvas, x=0, y=0, width = 1024, height=1024) {
    const newCanvas = document.createElement('canvas');
    newCanvas.width = width;
    newCanvas.height = height;

    const ctx = newCanvas.getContext('2d');
    ctx.drawImage(canvas, x, y, width, height, 0, 0, newCanvas.width, newCanvas.height);

    return newCanvas;
}

/**
 * @param {Blob} blob 
 * @returns {Promise<HTMLCanvasElement>}
 */
export function blobToCanvas(blob) {
    return new Promise((res) => {
        const url = URL.createObjectURL(blob);
        const img = new Image();
        img.src = url;
        img.onload = () => {
            res(img);
        }
    });
}

/**
 * @param {HTMLCanvasElement} canvas 
 * @param {string|undefined} type 
 * @returns {Promise<File>}
 */
export function canvasToFile(canvas, type = 'image/jpg', filename = 'img.jpg') {
    return new Promise((res, rej) => {
        canvas.toBlob((blob) => {
            const file = new File([blob], filename, {
                type,
                lastModified: Date.now(),
            });
            res(file);
        }, type)
    })

}
