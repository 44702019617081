import { button } from "@formkit/icons";
import { UIPanel, UIDiv, UIButton } from "./libs/ui";
import { createEJXButton, createHeading } from "./utils.EJX";

import CloseImg from '../images/close.svg';
import EJXLogoImg from '../images/ejx-logo.svg';

function Overlay(editor) {
    const signals = editor.signals;

    const container = new UIPanel();
    container.setStyle('display', ['none']);
    container.dom.classList.add('Overlay-container');

	const dialog = document.createElement( 'dialog' );
	dialog.open = false;
	dialog.id = 'ejxDialog';
    container.dom.appendChild(dialog);

	const closeIcon = document.createElement( 'img' );
	closeIcon.src = CloseImg.src;
	const closeDialogButton = new UIButton();
	closeDialogButton.dom.id = 'closeDialogButton';
	closeDialogButton.dom.appendChild( closeIcon );
	closeDialogButton.onClick( () => signals.hideOverlay.dispatch() );
    dialog.appendChild(closeDialogButton.dom);

	const ejxLogo = document.createElement( 'img' );
	ejxLogo.src = EJXLogoImg.src;
	ejxLogo.style.top = '10px';
	ejxLogo.style.left = '30px';
    ejxLogo.style.position = 'absolute';
	ejxLogo.style.width = '100px';
	ejxLogo.style.height = 'auto';
	ejxLogo.style.display = 'block';
	dialog.appendChild( ejxLogo );

    const content = document.createElement('div');
    dialog.appendChild(content);

    signals.showOverlay.add((config) => {
        container.setStyle('display', ['block']);
        dialog.open = true;
        const title = createHeading(config.title);
        content.appendChild(title);

        if (config.description) {
            const description = document.createElement('p');
            description.innerText = config.description;
            content.appendChild(description);
        }

        const buttonFlexbox = document.createElement('div');
        buttonFlexbox.style.display = 'flex';
        buttonFlexbox.style.justifyContent = 'center';
        buttonFlexbox.style.gap = '8px';

        for(const button of config.buttons) {
            let disabled = false;
            const buttonElement = createEJXButton(button.text, () => {
                // Prevent double clicking of action buttons for async processes.
                const actionResult = button.action()
                if (actionResult instanceof Promise) {
                    buttonElement.disabled = true;
                    actionResult.then(() => {
                        buttonElement.disabled = false;
                    });
                }
            }, button.type);
            buttonElement.style.whiteSpace = 'nowrap';
            buttonFlexbox.appendChild(buttonElement);
        }

        content.appendChild(buttonFlexbox);

        container.dom.style.display = 'flex';
    });

    signals.hideOverlay.add(() => {
        container.setStyle('display', ['none']);
        container.dom.style.display = 'none';

        while(content.firstChild) {
            content.removeChild(content.lastChild);
        }
    });

    return container;
}

class ConfirmationOverlayCancelError extends Error {

}

/**
 * @typedef {Object} ConfirmationOverlayModel
 * @property {string} title
 * @property {string?} description
 * @property {string} confirmText
 * @property {cancelText?} cancelText
 * @returns {Promise<void>}
 */

/**
 * Shows a confirmation dialog with a promise based api.
 * @param {import('./Editor')} editor 
 * @param {ConfirmationOverlayModel} model 
 */
function showConfirmationOverlay( editor, model ) {
    return new Promise((res, rej) => {

        const buttons = [];

        if (model.cancelText) {
            buttons.push({
                text: model.cancelText,
                type: 'secondary',
                action: () => editor.signals.hideOverlay.dispatch(),
            })
        }
        editor.signals.hideOverlay.addOnce(() => {
            rej(new ConfirmationOverlayCancelError('User cancelled dialog'))
        })

        buttons.push({
            text: model.confirmText,
            type: 'primary',
            action: () => {
                res();
                editor.signals.hideOverlay.dispatch();
            },
        });

        editor.signals.showOverlay.dispatch({
            title: model.title,
            description: model.description,
            buttons,
        })
    });
}

export { Overlay, showConfirmationOverlay, ConfirmationOverlayCancelError };
