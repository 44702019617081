import { UIPanel, UIRow, UISpan, UISelect, UIText } from './libs/ui.js';
import { StorageService } from '../../../services/Storage';
import { createEJXButton } from './utils.EJX.js';
import { ExportUtils } from './utils/importexport.js';

function DialogThumbnail( editor ) {

	const config = editor.config;
	const signals = editor.signals;
    const strings = editor.strings;

	const container = new UIPanel();

    const instructionRow = new UIRow();
	const instruction = new UIText( 'Select a thumbnail to mint with.' );
    instruction.dom.style.textAlign = 'center';
	instruction.dom.style.margin = '20px auto';
	instruction.dom.style.color = '#C0FB00';
	instructionRow.add(instruction);
    container.add(instructionRow);

    const selectedRow = new UIRow();
    const selectedImage = document.createElement('img');
	selectedRow.dom.appendChild(selectedImage);
	selectedRow.dom.style.display = "flex";
	selectedRow.dom.style.justifyContent = "center";
	selectedRow.dom.style.paddingBottom = "10px";
	container.add(selectedRow);

	let selectedThumbnail;
	const selectThumbnail = (thumbnailTag) => {
		if(selectedThumbnail) {
			selectedThumbnail.classList.remove("thumbnail-selected");
		}
		selectedThumbnail = thumbnailTag;
		selectedThumbnail.classList.add("thumbnail-selected");
		selectedImage.src = selectedThumbnail.src;
	}

    const thumbnailsRow = new UIRow();
	thumbnailsRow.dom.classList.add("thumbnail-gallery");
	thumbnailsRow.dom.style.paddingTop = "10px";
	thumbnailsRow.dom.style.paddingBottom = "10px";
	container.add(thumbnailsRow);

	const updateThumbnails = ( result ) => {
		thumbnailsRow.clear();
		for(var i = 0; i < result.shots.length; i++) {
			const thumbnailTag = document.createElement('img');
			thumbnailTag.src = window.URL.createObjectURL(result.shots[i]);
			thumbnailTag.classList.add("thumbnail");
			thumbnailTag.onclick = () => {
				selectThumbnail(thumbnailTag);
			}
			thumbnailsRow.dom.appendChild(thumbnailTag);
			if(i == 0) selectThumbnail(thumbnailTag);
		}
	}
    
	signals.respondPlayerThumbnail.add(updateThumbnails);

	const mintRow = new UIRow();
	mintRow.dom.appendChild( createEJXButton( 'Mint', async () => {
		signals.closeDialog.dispatch();
		// Zip needs to be stored in indexedDB so it can be accessed once we nav to the minter page.
		const ss = new StorageService('ejxStorage', 'projectFiles');
		await ss.init();

		const thumbnail = await fetch(selectedThumbnail.src).then(result => result.blob());
		ss.addFile('thumbnail', thumbnail);

		const zip = await ExportUtils.generateProjectZip(editor);
		const zipBlob = new Blob([zip], {
			type: 'application/zip'
		})
		const zipFile = new File([zipBlob], 'EJX Project.zip', {
			type: 'application/zip',
		});
		await ss.addFile('projectZip', zipFile);

		window.open('/mint/', '_blank');

		signals.showOverlay.dispatch({
			title: "Opening minter in new tab",
			description: "If it's not opened, try making sure popups are not blocked or clicking the open button below",
			buttons: [
				{
					text: "Open",
					action: () => { window.open('/mint/', '_blank'); },
					style: "primary"
				},
				{
					text: "Close",
					action: () => { editor.signals.hideOverlay.dispatch() },
					style: "secondary",
				},
			],
		});

        editor.signals.loadingFinished.dispatch();

	}, 'primary' ) );
	container.add(mintRow);

	return container;

}

export { DialogThumbnail };
