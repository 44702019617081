import { UIDiv, UIInput, UIText, UIRow, UIPanel, UISelect, UICheckbox, UIColor, UINumber } from './libs/ui.js';

export function ProjectTypeParametersPanel( editor ) {

	const scene = editor.scene;
	const config = editor.config;
	const strings = editor.strings;
	const signals = editor.signals;

	const container = new UIDiv();
	// Title
    const facesContainer = new UIPanel();
    container.add(facesContainer);

	const headerRow = new UIRow();
	headerRow.add( new UIText( strings.getKey( 'sidebar/project/type/EJXCube/title' ).toUpperCase() ) );
	facesContainer.add( headerRow );

	const descriptionRow = new UIRow();
	descriptionRow.add( new UIText( strings.getKey( 'sidebar/project/type/EJXCube/description' ) ).setTextStyle( 'description' ) );
	facesContainer.add( descriptionRow );

	const parameters = new UIDiv();
	facesContainer.add( parameters );

	const buildParameters = () => {

		parameters.clear();

		const options = {
			"all" : 'All',
		};
		scene.children.forEach( ( obj ) => {
			options[ obj.uuid ] = `${obj.name} [${obj.type}]`;
		});

		for ( const side of [ 'Front', 'Right', 'Back', 'Left', 'Top', 'Bottom' ] ) {

			const sideRow = new UIRow();

			const sideSelect = new UISelect().setOptions( options ).setValue( config.getKey( `project/type/EJXCube/face${side}` ) ).setLeft( '130px' ).onChange( function () {

				config.setKey( `project/type/EJXCube/face${side}`, this.getValue() );
				signals.ejxCubeSettingsChanged.dispatch();

			} );

			sideRow.add( new UIText( strings.getKey( `sidebar/project/type/EJXCube/face${side}` ) ).setWidth( '110px' ) );
			sideRow.add( sideSelect );

			parameters.add( sideRow );
		}

	};

	buildParameters();

	signals.sceneGraphChanged.add( buildParameters );
	signals.editorCleared.add(buildParameters);
	signals.loadingFinished.add(buildParameters);
	signals.startPlayer.add( buildParameters );
	signals.stopPlayer.add( buildParameters );
    
    /**
     * EJX Cube Border styling 
     */

    const styleContainer = new UIPanel();
    container.add(styleContainer);

	const styleHeaderRow = new UIRow();
	styleHeaderRow.add( new UIText('CUBE BORDER') );
	styleContainer.add( styleHeaderRow );

    //   borderOn: true,
    //   borderWidth: 0.0025,
    //   borderFeather: 0.5,
    //   borderColor: new Color( 1.0, 1.0, 1.0 ),
    //   borderAlpha: 0.25,

	/**
    * Visible
    */
	const visibleRow = new UIDiv();
	styleContainer.add( visibleRow );
    visibleRow.setStyle('margin-bottom', ['5px']);
	visibleRow.add( new UIText('Visible').setWidth( '110px'));
	const visibleCheckbox = new UICheckbox(true).onChange( () => {
        config.setKey('project/type/EJXCube/borderOn', visibleCheckbox.getValue());
        signals.ejxCubeSettingsChanged.dispatch();
	} );
	visibleRow.add( visibleCheckbox );

	/**
    * Border Width
    */
	const borderWidthRow = new UIDiv();
	styleContainer.add( borderWidthRow );
    borderWidthRow.setStyle('margin-bottom', ['5px']);
	borderWidthRow.add( new UIText('Width').setWidth( '110px'));
	const borderWidthNumber = new UINumber(0.0025).onChange( () => {
        config.setKey('project/type/EJXCube/borderWidth', borderWidthNumber.getValue() * 0.1);
        signals.ejxCubeSettingsChanged.dispatch();
	} );
    borderWidthNumber.min = 0;
    borderWidthNumber.max = 1;
    borderWidthNumber.setWidth( '110px' )
	borderWidthRow.add( borderWidthNumber );
    
	/**
    * Border Feather
    */
	const borderFeatherRow = new UIDiv();
	styleContainer.add( borderFeatherRow );
    borderFeatherRow.setStyle('margin-bottom', ['5px']);
	borderFeatherRow.add( new UIText('Feather').setWidth( '110px'));
	const borderFeatherNumber = new UINumber(0.5).onChange( () => {
        config.setKey('project/type/EJXCube/borderFeather', borderFeatherNumber.getValue());
        signals.ejxCubeSettingsChanged.dispatch();
	} );
    borderFeatherNumber.min = 0;
    borderFeatherNumber.max = 1;
    borderFeatherNumber.setWidth( '110px' )
	borderFeatherRow.add( borderFeatherNumber );

	/**
    * Border Color
    */
	const borderColorRow = new UIDiv();
	styleContainer.add( borderColorRow );
    borderColorRow.setStyle('margin-bottom', ['5px']);
	borderColorRow.add( new UIText('Color').setWidth( '110px'));
	const borderColor = new UIColor().setValue('#ffffff').onInput( () => {
        config.setKey('project/type/EJXCube/borderColor', borderColor.getValue());
        signals.ejxCubeSettingsChanged.dispatch();
	} );
    borderColor.setWidth( '110px' )
	borderColorRow.add( borderColor );

	/**
    * Border Alpha
    */
	const borderAlphaRow = new UIDiv();
	styleContainer.add( borderAlphaRow );
    borderAlphaRow.setStyle('margin-bottom', ['5px']);
	borderAlphaRow.add( new UIText('Alpha').setWidth( '110px'));
	const borderAlphaNumber = new UINumber(0.25).onChange( () => {
        config.setKey('project/type/EJXCube/borderAlpha', borderAlphaNumber.getValue());
        signals.ejxCubeSettingsChanged.dispatch();
	} );
    borderAlphaNumber.min = 0;
    borderAlphaNumber.max = 1;
    borderAlphaNumber.setWidth( '110px' )
	borderAlphaRow.add( borderAlphaNumber );

	const updateStyleUi = () => {

        visibleCheckbox.setValue(config.getKey('project/type/EJXCube/borderOn'));
        borderWidthNumber.setValue(config.getKey('project/type/EJXCube/borderWidth') * 10);
        borderFeatherNumber.setValue(config.getKey('project/type/EJXCube/borderFeather'));
        borderColor.setValue(config.getKey('project/type/EJXCube/borderColor'));
        borderAlphaNumber.setValue(config.getKey('project/type/EJXCube/borderAlpha'));

	};

	updateStyleUi();

	signals.ejxCubeSettingsChanged.add( updateStyleUi )
	signals.editorCleared.add( updateStyleUi );
	signals.startPlayer.add( updateStyleUi );
	signals.stopPlayer.add( updateStyleUi );

	return container;
	
}
